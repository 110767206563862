import { CategoryBadge } from "@/components/badges/category-badge";
import { CustomButton } from "@/components/forms/custom/custom-button/custom-button";
import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import { CustomPagination } from "@/components/forms/custom/custom-pagination/custom-pagination";
import CustomSelect from "@/components/forms/custom/custom-select/custom-select";
import { SortArrow } from "@/components/forms/custom/custom-table/sort-arrow";
import { TableShadowWrapper } from "@/components/forms/custom/custom-table/table-shadow-wrapper";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { EListingCategory } from "@/enums/listing-enums";
import { useDebounce } from "@/hooks/use-debounce";
import { authedFetch } from "@/utils/authed-fetch";
import { dateToYearMonthDay } from "@/utils/date-fns";
import { useAuth } from "@clerk/clerk-react";
import { cilX } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CBadge,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
  CTooltip,
} from "@coreui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

type TColumn = {
  key: string;
  label: React.ReactNode;
};

type TQuery = {
  count: number;
  listings: {
    id: number;
    category: EListingCategory;
    title: string;
    description: string;
    country: string;
    deadline: string;
    created_at: string;
  }[];
};

type TSpreadableParams = {
  p?: string;
  ps?: string;
  title?: string;
  orderBy?: string;
  orderDir?: string;
  country?: string;
  category?: string;
  description?: string;
};

export const DiscoverTable = () => {
  const { getToken } = useAuth();
  const [mouseOverIndex, setMouseOverIndex] = useState<number | null>(null);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const p = parseInt(searchParams.get("p") || "1");
  const ps = parseInt(searchParams.get("ps") || "0");
  const titleRaw = searchParams.get("title") || "";
  const countryRaw = searchParams.get("country") || "";
  const category = searchParams.get("category") || "";
  const orderBy = searchParams.get("orderBy") || "";
  const orderDir = searchParams.get("orderDir") || "";
  const descriptionRaw = searchParams.get("description") || "";

  const title = useDebounce(titleRaw, 250);
  const country = useDebounce(countryRaw, 250);
  const description = useDebounce(descriptionRaw, 250);

  const spreadableParams: TSpreadableParams = {};
  if (p && p < 1) spreadableParams.p = p.toString();
  if (ps) spreadableParams.ps = ps.toString();
  if (title) spreadableParams.title = title;
  if (country) spreadableParams.country = country;
  if (orderBy) spreadableParams.orderBy = orderBy;
  if (orderDir) spreadableParams.orderDir = orderDir;
  if (description) spreadableParams.description = description;
  if (category) spreadableParams.category = category;

  const setPage = (page: number) =>
    setSearchParams({ ...spreadableParams, p: page.toString() });
  const setOrder = (orderBy: string, orderDir: string) =>
    setSearchParams({ ...spreadableParams, orderBy, orderDir });

  const { data, isLoading } = useQuery<TQuery>({
    queryKey: [
      "discover-table",
      p,
      ps,
      orderBy,
      orderDir,
      title,
      country,
      category,
      description,
    ],
    queryFn: async ({ queryKey }) => {
      const [
        _,
        p,
        ps,
        orderBy,
        orderDir,
        title,
        country,
        category,
        description,
      ] = queryKey;

      const pParam = p ? `p=${p}` : "";
      const psParam = ps ? `ps=${ps}` : "";
      const orderByParam = orderBy ? `orderBy=${orderBy}` : "";
      const orderDirParam = orderDir ? `orderDir=${orderDir}` : "";
      const titleParam = title ? `title=${title}` : "";
      const countryParam = country ? `country=${country}` : "";
      const categoryParam = category ? `category=${category}` : "";
      const descriptionParam = description ? `description=${description}` : "";

      const params =
        p ||
        ps ||
        orderBy ||
        orderDir ||
        title ||
        country ||
        category ||
        description
          ? `?${[
              pParam,
              psParam,
              orderByParam,
              orderDirParam,
              titleParam,
              countryParam,
              categoryParam,
              descriptionParam,
            ]
              .filter((param) => param)
              .join("&")}`
          : "";

      const response = await authedFetch({
        endpoint: `api/provider/discover${params}`,
        token: await getToken(),
      });
      return response.json();
    },
  });

  const pageCount = Math.ceil((data?.count || 0) / 10);

  const count = data?.count || 0;

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setSearchParams({ ...spreadableParams, title: e.target.value });
    } else {
      delete spreadableParams.title;
      setSearchParams({ ...spreadableParams });
    }
  };

  const handleChangeCountry = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setSearchParams({ ...spreadableParams, country: e.target.value });
    } else {
      delete spreadableParams.country;
      setSearchParams({ ...spreadableParams });
    }
  };

  const handleChangeCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      setSearchParams({ ...spreadableParams, category: e.target.value });
    } else {
      delete spreadableParams.category;
      setSearchParams({ ...spreadableParams });
    }
  };

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setSearchParams({ ...spreadableParams, description: e.target.value });
    } else {
      delete spreadableParams.description;
      setSearchParams({ ...spreadableParams });
    }
  };

  const handleClearFilterBadge = (key: keyof TSpreadableParams) => {
    delete spreadableParams[key];
    setSearchParams({ ...spreadableParams });
  };
  const handleClearFilterAll = () => {
    delete spreadableParams.title;
    delete spreadableParams.country;
    delete spreadableParams.category;
    delete spreadableParams.description;
    setSearchParams({ ...spreadableParams });
  };

  const sortArrow = (type: string) => {
    return (
      <SortArrow
        columnName={type}
        orderBy={orderBy}
        orderDir={orderDir}
        onClick={setOrder}
      />
    );
  };

  const columns: TColumn[] = [
    {
      key: "id",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>#</span>
          {sortArrow("id")}
        </div>
      ),
    },
    {
      key: "category",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>Category</span>
          {sortArrow("category")}
        </div>
      ),
    },
    {
      key: "title",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>Title</span>
          {sortArrow("title")}
        </div>
      ),
    },
    {
      key: "country",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>Country</span>
          {sortArrow("country")}
        </div>
      ),
    },
    {
      key: "description",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>Description</span>
        </div>
      ),
    },
    {
      key: "deadline",
      label: (
        <div className="flex items-center h-full gap-1">
          <span className="flex flex-col items-center text-sm">
            <span>Available</span>
            <span>Until</span>
          </span>
          {sortArrow("deadline")}
        </div>
      ),
    },
    {
      key: "actions",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>Actions</span>
        </div>
      ),
    },
  ];

  return (
    <>
      <h3 className="flex items-center pb-2">
        <span>Search listings</span>
      </h3>
      <div className="flex flex-wrap items-center gap-3 px-3 py-3 bg-white rounded-t-xl">
        <div className="flex items-center gap-2">
          <span className="font-bold">Title: </span>
          <div>
            <CustomInput
              placeholder="Search..."
              value={titleRaw}
              onChange={handleChangeTitle}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setSearchParams({ ...spreadableParams, search: "" });
                }
              }}
              divWrap
              clearable
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <span className="font-bold">Category: </span>
          <CustomSelect
            id="category"
            placeholder="placeholder"
            presetOptions="categories"
            emptyOptionLabel="Select category"
            defaultValue={undefined}
            value={category}
            onChange={handleChangeCategory}
            divWrap
          />
        </div>
        <div className="flex items-center gap-2">
          <span className="font-bold">Country: </span>
          <div>
            <CustomInput
              placeholder="Search..."
              value={countryRaw}
              onChange={handleChangeCountry}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setSearchParams({ ...spreadableParams, country: "" });
                }
              }}
              divWrap
              clearable
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <span className="font-bold">Description: </span>
          <div>
            <CustomInput
              placeholder="Search..."
              value={descriptionRaw}
              onChange={handleChangeDescription}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setSearchParams({ ...spreadableParams, description: "" });
                }
              }}
              divWrap
              clearable
            />
          </div>
        </div>
      </div>
      {title || country || category || description ? (
        <div className="flex px-3 pb-3 bg-white">
          <div className="flex items-stretch gap-2 p-2 bg-gray-100 rounded-lg">
            {title && (
              <CBadge color="info">
                <span className="flex items-center gap-1">
                  {`Title: ${title}`}
                  <CIcon
                    icon={cilX}
                    size="sm"
                    onClick={() => handleClearFilterBadge("title")}
                    className="cursor-pointer"
                  />
                </span>
              </CBadge>
            )}
            {category && (
              <CBadge color="info">
                <span className="flex items-center gap-1">
                  {`Category: ${category}`}
                  <CIcon
                    icon={cilX}
                    size="sm"
                    onClick={() => handleClearFilterBadge("category")}
                    className="cursor-pointer"
                  />
                </span>
              </CBadge>
            )}
            {country && (
              <CBadge color="info">
                <span className="flex items-center gap-1">
                  {`Country: ${country}`}
                  <CIcon
                    icon={cilX}
                    size="sm"
                    onClick={() => handleClearFilterBadge("country")}
                    className="cursor-pointer"
                  />
                </span>
              </CBadge>
            )}
            {description && (
              <CBadge color="info">
                <span className="flex items-center gap-1">
                  {`Description: ${description}`}
                  <CIcon
                    icon={cilX}
                    size="sm"
                    onClick={() => handleClearFilterBadge("description")}
                    className="cursor-pointer"
                  />
                </span>
              </CBadge>
            )}
            <CBadge color="danger">
              <CIcon
                icon={cilX}
                size="sm"
                onClick={() => handleClearFilterAll()}
                className="cursor-pointer"
              />
            </CBadge>
          </div>
        </div>
      ) : null}
      <div className="self-stretch h-[5px] bg-white mb-[-5px]" />
      <TableShadowWrapper>
        <CTable
          style={{
            marginBottom: 0,
          }}
          className="bg-white rounded-lg shadow-lg"
        >
          <CTableBody
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto auto 1fr auto auto",
            }}
            onMouseLeave={() => {
              setMouseOverIndex(() => null);
            }}
          >
            <CTableRow
              className="text-base font-extrabold tracking-wider text-gray-900 uppercase bg-gray-200"
              style={{
                display: "contents",
              }}
              onMouseOver={() => {
                setMouseOverIndex(() => null);
              }}
            >
              {columns.map((column) => (
                <CTableHeaderCell
                  className="py-4 border-b-2 border-gray-400"
                  scope="col"
                  key={column.key}
                >
                  {column.label}
                </CTableHeaderCell>
              ))}
            </CTableRow>
            {isLoading
              ? columns.map((_) => (
                  <CTableDataCell>
                    <DefaultSkeleton />
                  </CTableDataCell>
                ))
              : data?.listings?.map((item, _index) => (
                  <CTableRow
                    onMouseOver={() => {
                      setMouseOverIndex(() => _index);
                    }}
                    key={item?.id}
                    style={{
                      display: "contents",
                    }}
                    color={_index === mouseOverIndex ? "info" : ""}
                  >
                    <CTableDataCell>{item?.id}</CTableDataCell>
                    <CTableDataCell>
                      <CategoryBadge category={item?.category} />
                    </CTableDataCell>
                    <CTableDataCell>{item?.title}</CTableDataCell>
                    <CTableDataCell>{item?.country}</CTableDataCell>
                    <CTableDataCell
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <CTooltip
                        content={item.description}
                        style={
                          {
                            "--cui-tooltip-bg": "var(--cui-secondary)",
                          } as any
                        }
                      >
                        <span>{item.description}</span>
                      </CTooltip>
                    </CTableDataCell>
                    <CTableDataCell>
                      {dateToYearMonthDay(item?.deadline)}
                    </CTableDataCell>
                    <CTableDataCell className="text-center">
                      <CustomButton
                        variant="outline"
                        color="info"
                        onClick={() => {
                          navigate(`/discover/${item.id}`, {
                            state: "discover-preview",
                          });
                        }}
                      >
                        Show
                      </CustomButton>
                    </CTableDataCell>
                  </CTableRow>
                ))}
          </CTableBody>
        </CTable>
      </TableShadowWrapper>

      <div className="flex justify-center">
        <CustomPagination
          pageCount={pageCount}
          currentPage={p}
          onPageChange={setPage}
        />
      </div>
      {!isLoading && count === 0 && (
        <div className="flex flex-col items-center justify-center h-64">
          <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg shadow-lg">
            <p className="text-xl font-bold text-gray-700">No Data Available</p>
            <p className="mt-2 text-center text-gray-500">
              There is currently no data to display. Create your first item!
            </p>
            <p className="mt-2 text-center text-gray-500">
              In case of issues, reach out to{" "}
              <a
                href="mailto:support@machinevertical.com"
                className="text-blue-500 underline"
              >
                support@machinevertical.com
              </a>
            </p>
          </div>
        </div>
      )}
    </>
  );
};
