import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { setModalListingPreview } from "@/store/modal/slice";
import { authedFetch } from "@/utils/authed-fetch";
import { useAuth } from "@clerk/clerk-react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CustomStatusBadge } from "../forms/custom/custom-badge/custom-status-badge";
import { CustomButton } from "../forms/custom/custom-button/custom-button";
import { addToastMain } from "@/store/toast/slice";
import { EListingStatus } from "@/enums/listing-enums";
import { StepInProcess } from "./components/step-in-process";
import { CustomMarkdown } from "@/components/custom-markdown/custom-markdown";
import { CategoryBadge } from "@/components/badges/category-badge";
import { loadingOrData } from "@/utils/loading-or-data";

export const ListingPreviewModal = () => {
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  const modalState = useAppSelector((state) => state.modal.listingPreviewModal);
  const isOpen = modalState.isOpen;
  const listingId = modalState.listingId;

  const { data, isLoading } = useQuery({
    queryKey: [`listings-edit`, listingId],
    queryFn: async ({ queryKey }) => {
      const [_, listingId] = queryKey;
      if (!listingId) return null;
      return authedFetch({
        endpoint: `api/office/listing/edit/${listingId}`,
        token: await getToken(),
      }).then((res) => res.json());
    },
  });

  const startedMutation = useMutation({
    mutationFn: async () => {
      return authedFetch({
        endpoint: `api/office/listing/start/${listingId}`,
        method: "PATCH",
        token: await getToken(),
      }).then((res) => {
        if (res.status >= 400) {
          dispatch(
            addToastMain({
              mainToast: "custom_toast",
              mainToastColor: "danger",
              mainToastContent: `${res.status}: ${res.statusText}`,
            })
          );
        } else {
          dispatch(
            addToastMain({
              mainToast: "custom_toast",
              mainToastColor: "success",
              mainToastContent: `Listing #${data.id} started!`,
            })
          );
          onModalClose();
        }
      });
    },
  });

  const stopPublishingMutation = useMutation({
    mutationFn: async () => {
      return authedFetch({
        endpoint: `api/office/listing/close/${listingId}`,
        method: "PATCH",
        token: await getToken(),
      }).then((res) => {
        if (res.status >= 400) {
          dispatch(
            addToastMain({
              mainToast: "custom_toast",
              mainToastColor: "danger",
              mainToastContent: `${res.status}: ${res.statusText}`,
            })
          );
        } else {
          dispatch(
            addToastMain({
              mainToast: "custom_toast",
              mainToastColor: "success",
              mainToastContent: `Listing #${data.id} closed!`,
            })
          );
          onModalClose();
        }
      });
    },
  });

  const onStartedHandler = () => {
    startedMutation.mutate();
  };

  const onStopPublishingHandler = () => {
    stopPublishingMutation.mutate();
  };

  const onModalClose = () => {
    dispatch(setModalListingPreview({ isOpen: false }));
  };

  return (
    <CModal visible={isOpen} size="xl" onClose={onModalClose}>
      <CModalHeader closeButton>
        <CModalTitle>Listing Preview</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="flex items-center gap-2">
          <div className="flex flex-col">
            <span className="flex items-center gap-2 text-md">
              {data?.id ? `#${data.id}` : ""}
              <CustomStatusBadge status={data?.status} />
            </span>
            <span className="text-2xl">{data?.title ? data.title : ""}</span>
          </div>
          <span className="text-xl"></span>
        </div>
        <div className="pt-4 pb-2">
          <StepInProcess status={data?.status} />
        </div>
        <div className="flex ">
          <div className="flex-1">
            <p>(IMAGES)</p>
          </div>
          <div className="flex flex-col flex-1">
            <h5 className="px-2 pt-4 pb-0">Basic Information</h5>
            <div className="p-2 border border-gray-200 rounded-lg">
              <label className="font-bold form-label">ID</label>
              <p>{loadingOrData({ data: data?.id, isLoading, before: "#" })}</p>
              <label className="font-bold form-label">Title</label>
              <p>{loadingOrData({ data: data?.title, isLoading })}</p>
              <label className="font-bold form-label">Status</label>
              <p className="text-xl">
                <CustomStatusBadge status={data?.status} />
              </p>
              <label className="font-bold form-label">Category</label>
              <p className="text-xl">
                <CategoryBadge category={data?.category} />
                {/* {loadingOrData({data: data?.category)} */}
              </p>
              <label className="font-bold form-label">Description</label>
              <p>{loadingOrData({ data: data?.description, isLoading })}</p>
            </div>

            <h5 className="px-2 pt-4 pb-0">Specifications</h5>
            <div className="p-2 border border-gray-200 rounded-lg">
              <label className="font-bold form-label">Required</label>
              <CustomMarkdown>
                {
                  loadingOrData({
                    data: data?.specification,
                    isLoading,
                  }) as string
                }
              </CustomMarkdown>
              <label className="italic font-bold form-label">Optional</label>
              <CustomMarkdown>
                {loadingOrData({
                  data: data?.optional_specification,
                  isLoading,
                })}
              </CustomMarkdown>
            </div>

            <h5 className="px-2 pt-4 pb-0">Listing Setup</h5>
            <div className="p-2 border border-gray-200 rounded-lg">
              <label className="font-bold form-label">Deadline</label>
              <p>{loadingOrData({ data: data?.deadline, isLoading })}</p>
              <label className="font-bold form-label">Participants</label>
              <p>{loadingOrData({ data: data?.participants, isLoading })}</p>
            </div>
          </div>
        </div>
        <h5 className="px-2 pt-4 pb-0">Location</h5>
        <div className="p-2 border border-gray-200 rounded-lg">
          <div className="flex justify-between ">
            <div className="flex-1">
              <label className="font-bold form-label">Country</label>
              <p>{loadingOrData({ data: data?.country, isLoading })}</p>
            </div>
            <div className="flex-1">
              <label className="font-bold form-label">Region</label>
              <p>{loadingOrData({ data: data?.region, isLoading })}</p>
            </div>
          </div>
        </div>
        {/* <p>{message}</p> */}
        {/* <h6>{messageSecondary}</h6> */}
      </CModalBody>
      <CModalFooter>
        <CButton
          type="button"
          color={"dark"}
          variant={"outline"}
          onClick={() => {
            onModalClose();
          }}
        >
          Cancel
        </CButton>
        {data?.status === EListingStatus.OPEN && (
          <CustomButton
            type="button"
            color="info"
            className="text-white"
            onClick={onStartedHandler}
          >
            Move to Started
          </CustomButton>
        )}
        {data?.status === EListingStatus.STARTED && (
          <CButton
            color="info"
            className="text-white"
            onClick={onStopPublishingHandler}
          >
            Close Listing
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  );
};
