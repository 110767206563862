import { CPagination, CPaginationItem } from "@coreui/react";
import { CPaginationProps } from "@coreui/react/dist/esm/components/pagination/CPagination";

type TCustomPagination = {
  pageCount: number;
  currentPage: number;
  onPageChange: (page: number) => void;
} & CPaginationProps;

export const CustomPagination = (props: TCustomPagination) => {
  const { pageCount, currentPage, onPageChange, ...restProps } = props;

  if (pageCount <= 1) return null;

  return (
    <CPagination {...restProps}>
      <CPaginationItem
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
        className={`${currentPage === 0 ? "" : "cursor-pointer"}`}
      >
        Previous
      </CPaginationItem>
      {pageCount > 0 &&
        Array.from({ length: pageCount }, (_, i) => i + 1).map((item) => (
          <CPaginationItem
            key={item}
            active={item === currentPage}
            className={`${item === currentPage ? "" : "cursor-pointer"}`}
            onClick={() => props.onPageChange(item)}
          >
            {item}
          </CPaginationItem>
        ))}
      <CPaginationItem
        disabled={currentPage === pageCount}
        className={`${currentPage === pageCount ? "" : "cursor-pointer"}`}
        onClick={() => onPageChange(currentPage + 1)}
      >
        Next
      </CPaginationItem>
    </CPagination>
  );
};
