import { CFormRange, CFormSwitch } from "@coreui/react";
import { TListingCreateForm } from "../listing-create";
import { MAX_PARTICIPANTS, MIN_PARTICIPANTS } from "@/constants/form-constatns";

type TListingCreateSettingsProps = {
  values: TListingCreateForm;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  hasSubmittedDraft: boolean;
  hasSubmittedPublish: boolean;
};

export const ListingCreateSettings = ({
  values,
  handleChange,
  setFieldValue,
  hasSubmittedDraft,
  hasSubmittedPublish,
}: TListingCreateSettingsProps) => {
  const hasSubmittedEither = hasSubmittedDraft || hasSubmittedPublish;

  return (
    <>
      <h5 className="px-2 pt-4 pb-0">Settings</h5>
      <div className="flex flex-col gap-4 p-3 border border-gray-200 rounded-lg">
        <div className="">
          <label className="form-label">Hidden</label>
          <CFormSwitch
            size="xl"
            valid={hasSubmittedEither}
            onChange={() => {
              setFieldValue("is_hidden", !values.is_hidden);
            }}
            value={values.is_hidden ? 1 : 0}
            checked={values.is_hidden}
          />
        </div>
        <div className="flex flex-col">
          <CFormRange
            id="participants"
            min={MIN_PARTICIPANTS}
            max={MAX_PARTICIPANTS}
            label={
              <div className="flex mb-[-10px] flex-col">
                <span>Participants: {values.participants}</span>
                <div className="flex justify-between">
                  <span>{MIN_PARTICIPANTS}</span>
                  <span>{MAX_PARTICIPANTS}</span>
                </div>
              </div>
            }
            value={values.participants}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};
