import { useAppDispatch } from "@/store/hooks";
import {
  setModalConfirmation,
  TModalConfirmationState,
} from "@/store/modal/slice";

export const useModalsListingViewEdit = () => {
  const dispatch = useAppDispatch();

  const dispatchConfirmationModalAction = ({
    title,
    message,
    buttons,
  }: {
    title: TModalConfirmationState["title"];
    message: TModalConfirmationState["message"];
    buttons: TModalConfirmationState["buttons"];
  }) => {
    dispatch(
      setModalConfirmation({
        isOpen: true,
        title,
        message,
        buttons,
      })
    );
  };

  const modalPublishWithoutAttachments = ({
    onConfirm,
  }: {
    onConfirm: () => void;
  }) =>
    dispatchConfirmationModalAction({
      title: "Listing Publishing",
      message: "Are you sure you want to publish without attachments?",
      buttons: {
        cancel: {
          label: "Cancel",
          variant: "outline",
        },
        confirm: {
          label: "Publish",
          color: "info",
          onClick: onConfirm,
        },
      },
    });

  const modalEditCancelConfirmation = ({
    onConfirm,
  }: {
    onConfirm: () => void;
  }) =>
    dispatchConfirmationModalAction({
      title: "Unsaved Changes",
      message: "You have unsaved changes, are you sure you want to leave?",
      buttons: {
        cancel: {
          label: "Cancel",
          variant: "outline",
        },
        confirm: {
          label: "Leave",
          color: "danger",
          onClick: onConfirm,
        },
      },
    });

  return {
    modalPublishWithoutAttachments,
    modalEditCancelConfirmation,
  };
};
