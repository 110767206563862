import { CategoryBadge } from "@/components/badges/category-badge";
import { CustomMarkdown } from "@/components/custom-markdown/custom-markdown";
import { EListingCategory } from "@/enums/listing-enums";
import { authedFetch } from "@/utils/authed-fetch";
import { useAuth } from "@clerk/clerk-react";
import { cilCalendar } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useEnrollMutation } from "../../hooks/use-enroll-mutation";
import { useDeleteMyEnrollmentMutation } from "../../hooks/use-delete-my-enrollment-mutation";
import { EListingStatus } from "@/enums/listing-enums";
import { EEnrollmentStatus } from "@/enums/enrollment-enums";
import { useEffect, useMemo } from "react";
import { StepInProcessEnrollment } from "../../components/step-in-process-enrollment";
import { useAppDispatch } from "@/store/hooks";
import { setModalConfirmation } from "@/store/modal/slice";
import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { StringSkeleton } from "@/components/loaders/default-skeleton";
import { SlideAppearWrapper } from "../../components/slide-appear-wrapper";
import { PageDiscoverShowButtons } from "../components/page-discover-show-buttons";
import { AttachmentTable } from "@/components/attachments/attachment-table/attachment-table";
import { integerToFilesize } from "@/utils/integer-to-filesize";
import { dateToYearMonthDay } from "@/utils/date-fns";

type TQuery = {
  id: number;
  title: string;
  description: string;
  created_at: string;
  created_by: string;
  status: EListingStatus;
  category: EListingCategory;
  specification: string;
  is_hidden: 0 | 1;
  budget: number;
  optional_specification: string;
  participants: number;
  deadline: string;
  setup_id: number;
  region: string;
  country: string;
  enrollment_status: EEnrollmentStatus;
  ListingAttachments?: {
    attachment_url: string;
    display_name: string;
    size: number;
    created_at: string;
  }[];
};

// @ts-ignore
const endpoint = import.meta.env.VITE_API_ENDPOINT;

export const PageDiscoverShow = () => {
  const { listingId = "0" } = useParams();
  const { setBreadcrumbs } = useBreadcrumbs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getToken } = useAuth();

  const { data, isLoading } = useQuery<TQuery>({
    queryKey: [`discover-show`, listingId],
    queryFn: async ({ queryKey }) => {
      const [_, listingId] = queryKey;
      if (!listingId) return null;
      return authedFetch({
        endpoint: `api/provider/discover/preview/${listingId}`,
        token: await getToken(),
      }).then((res) => res.json());
    },
  });

  const isEnrollmentInitiated = useMemo(
    () => data?.enrollment_status === EEnrollmentStatus.INITIATED,
    [data?.enrollment_status]
  );

  const goBack = () => navigate(-1);

  const deleteMutation = useDeleteMyEnrollmentMutation({
    onSuccess: goBack,
    onError: goBack,
  });

  const enrollMutation = useEnrollMutation({
    onSuccess: goBack,
  });

  const handleDelete = () => {
    if (!data?.id) return;
    deleteMutation.mutate({ id: data.id });
  };

  const handleParticipate = () => {
    if (!data?.id) return;
    dispatch(
      setModalConfirmation({
        isOpen: true,
        title: "Participation Confirmation",
        message: "Do you want to participate in a listing below?",
        messageSecondary: `#${data?.id}: ${data?.title}`,
        buttons: {
          cancel: {
            label: "Cancel",
            color: "dark",
            variant: "outline",
          },
          confirm: {
            label: "Yes, Start Participation",
            color: "info",
            className: "text-white",
            onClick: () => enrollMutation.mutate({ id: data.id }),
          },
        },
      })
    );
  };

  const loadingOrData = (data?: string | number) => {
    if (isLoading) {
      return "loading...";
    }
    if (!data) {
      return "-";
    }
    return `${data}`;
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        link: "/",
        label: "Home",
      },
      {
        label: "Discover",
        link: "/discover",
      },
      {
        link: `/discover/${listingId}`,
        label: `Listing #${listingId}`,
      },
    ]);
  }, []);

  return (
    <div>
      <div className="flex items-start justify-between">
        <div>
          <h3 className="mb-0">
            {isLoading ? <StringSkeleton /> : data?.title}
          </h3>
          <span className="flex items-center gap-2 text-slate-500">
            <span className="flex">
              Listing ID:{" "}
              {isLoading ? (
                <StringSkeleton className="w-10 ml-3" />
              ) : (
                `#${data?.id}`
              )}
            </span>
          </span>
          <div className="flex h-6">
            {isLoading ? (
              <StringSkeleton className="w-20" />
            ) : (
              <div>
                <CategoryBadge category={data?.category as EListingCategory} />
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <CIcon icon={cilCalendar} />
          <div className="flex gap-1">
            <span>Available until:</span>
            {isLoading ? (
              <StringSkeleton className="w-[95px]" />
            ) : (
              <span className="font-bold">{data?.deadline}</span>
            )}
          </div>
        </div>
      </div>

      {data?.enrollment_status ? (
        <SlideAppearWrapper isLoaded={!isLoading && !!data}>
          <h5 className="px-2 pt-4 pb-0">Enrollment Status</h5>
          <StepInProcessEnrollment status={data.enrollment_status} />
        </SlideAppearWrapper>
      ) : null}

      <h5 className="px-2 pt-4 pb-0">Basic Information</h5>
      <div className="p-2 border border-gray-200 rounded-lg">
        <label className="font-bold form-label">Description</label>
        <p>{loadingOrData(data?.description)}</p>
      </div>

      <h5 className="px-2 pt-4 pb-0">Specifications</h5>
      <div className="flex flex-col p-2 border border-gray-200 rounded-lg">
        <label className="font-bold form-label">Required</label>
        {isLoading ? (
          <StringSkeleton className="w-[50%] h-6" />
        ) : (
          <CustomMarkdown>{data?.specification || "-"}</CustomMarkdown>
        )}
        <label className="italic font-bold form-label">Optional</label>
        {isLoading ? (
          <StringSkeleton className="w-[50%] h-6" />
        ) : (
          <CustomMarkdown>{data?.optional_specification || "-"}</CustomMarkdown>
        )}
      </div>

      <h5 className="px-2 pt-4 pb-0">Attachments</h5>
      <AttachmentTable
        attachments={(data?.ListingAttachments || []).map((file) => {
          const splitName = file.display_name.split(".");
          const type = splitName[splitName.length - 1] || "";
          // const fileUrl = `${endpoint}/api/${file.attachment_url}`;
          const fileUrl = file.attachment_url;

          return {
            fileUrl,
            name: file.display_name,
            size: integerToFilesize(file.size),
            type,
            uploadedAt: dateToYearMonthDay(file.created_at),
            markedToDelete: false,
            indexOfNew: null,
            actions: null,
          };
        })}
        isEditing={false}
        isEquipmentProvider={true}
      />

      <div className="flex justify-end gap-2 pt-4">
        <PageDiscoverShowButtons
          goBack={goBack}
          isEnrollmentInitiated={isEnrollmentInitiated}
          handleDelete={handleDelete}
          handleParticipate={handleParticipate}
          enrollmentStatus={data?.enrollment_status}
        />
      </div>
    </div>
  );
};
