import { useEffect, useRef, useState } from "react";
import {
  ListingsTable,
  TListingsTableProps,
} from "../listings/components/listings-table";
import { CustomPagination } from "@/components/forms/custom/custom-pagination/custom-pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import { useAppDispatch } from "@/store/hooks";
import { useQuery } from "@tanstack/react-query";
import { authedFetch } from "@/utils/authed-fetch";
import { CButton, CFormSwitch, CTooltip } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilBan, cilPen } from "@coreui/icons";
import { setModalConfirmation } from "@/store/modal/slice";
import { useHideListingMutation } from "../hooks/use-hide-listing-mutation";
import { useDeleteListingMutation } from "../hooks/use-delete-listing-mutation";
import { CustomStatusBadge } from "@/components/forms/custom/custom-badge/custom-status-badge";
import { dateUtcToLocal } from "@/utils/date-fns";
import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { EListingStatus } from "@/enums/listing-enums";
import { TableShadowWrapper } from "@/components/forms/custom/custom-table/table-shadow-wrapper";

type TQuery = {
  listings: {
    id: number;
    group_id: number;
    title: string;
    created_by: string;
    created_at: string;
    status: EListingStatus;
    category: string;
    is_hidden: 0 | 1;
  }[];
  count: number;
};

const PageMyDrafts = () => {
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const pageRef = useRef(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const { setBreadcrumbs } = useBreadcrumbs();

  const p = parseInt(searchParams.get("p") || "1");
  const ps = parseInt(searchParams.get("ps") || "0");
  const setPage = (page: number) => setSearchParams({ p: page.toString() });

  const { data, isLoading, refetch } = useQuery<TQuery>({
    queryKey: ["my-drafts", p, ps],
    queryFn: async ({ queryKey }) => {
      const [_, p, ps] = queryKey;
      const pParam = p ? `p=${p}` : "";
      const psParam = ps ? `ps=${ps}` : "";
      const params =
        p || ps
          ? `?${[pParam, psParam].filter((param) => param).join("&")}`
          : "";
      return authedFetch({
        endpoint: `api/office/listing/my-drafts${params}`,
        token: await getToken(),
      }).then((res) => res.json());
    },
  });

  const pageCount = Math.ceil((data?.count || 0) / 10);

  const hideMutation = useHideListingMutation({ refetch });
  const deleteMutation = useDeleteListingMutation({ refetch });

  const handleDelete = (id: number) => {
    deleteMutation.mutate({ id });
  };

  const handleHide = (id: number, is_hidden: boolean) => {
    hideMutation.mutate({ id, is_hidden: !is_hidden });
  };

  const dispatchDeleteModal = ({ id, title }: { id: number; title: string }) =>
    setModalConfirmation({
      isOpen: true,
      title: "Delete Listing",
      message: "Are you sure you want to delete this listing?",
      messageSecondary: `#${id}: ${title}`,
      buttons: {
        cancel: {
          label: "Cancel",
          color: "secondary",
          variant: "outline",
        },
        confirm: {
          label: "Delete",
          color: "danger",
          onClick: () => handleDelete(id),
        },
      },
    });

  const dispatchHideModal = ({
    id,
    title,
    is_hidden,
  }: {
    id: number;
    title: string;
    is_hidden: boolean;
  }) =>
    setModalConfirmation({
      isOpen: true,
      title: is_hidden ? "Reveal Listing" : "Hide Listing",
      message: `Are you sure you want to ${is_hidden ? "reveal" : "hide"} this listing?`,
      messageSecondary: `#${id}: ${title}`,
      buttons: {
        cancel: {
          label: "Cancel",
          color: "secondary",
          variant: "outline",
        },
        confirm: {
          label: is_hidden ? "Reveal" : "Hide",
          color: is_hidden ? "info" : "danger",
          onClick: () => handleHide(id, is_hidden),
        },
      },
    });

  const tbody: TListingsTableProps["tbody"] =
    data?.listings?.map?.((item) => ({
      id: item.id,
      group_id: item.group_id,
      status: (
        <h5>
          <CustomStatusBadge status={item.status} />
        </h5>
      ),
      title: item.title,
      category: item.category,
      created_by: item?.created_by,
      created_at: (
        <span className="whitespace-nowrap">
          {dateUtcToLocal(item.created_at)}
        </span>
      ),
      hidden: (
        <div className="flex justify-center">
          <CFormSwitch
            checked={!!item.is_hidden}
            size="xl"
            color="info"
            onChange={() => {}}
            onClick={() => {
              dispatch(
                dispatchHideModal({
                  id: item.id,
                  title: item.title,
                  is_hidden: !!item.is_hidden,
                })
              );
            }}
          />
        </div>
      ),
      actions: (
        <div className="flex w-auto gap-1">
          <CTooltip content="Edit" container={pageRef.current}>
            <CButton
              color="info"
              onClick={() =>
                navigate(`/listing/edit/${item.id}`, {
                  state: "listing-edit",
                })
              }
            >
              <CIcon icon={cilPen} />
            </CButton>
          </CTooltip>

          <CTooltip content="Remove" container={pageRef.current}>
            <CButton
              color="danger"
              onClick={() => {
                dispatch(
                  dispatchDeleteModal({ id: item.id, title: item.title })
                );
              }}
            >
              <CIcon icon={cilBan} />
            </CButton>
          </CTooltip>
        </div>
      ),
    })) || [];

  useEffect(() => {
    if ((p < 0 || p > pageCount) && !isLoading) {
      setPage(1);
    }
    if (firstLoad) {
      setFirstLoad(false); // needed for reliable ref mount
    } else {
      refetch();
    }
  }, [p, pageCount, firstLoad, isLoading]);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Home",
        link: "/",
      },
      {
        label: "My Drafts",
        link: "/listings/my-drafts",
      },
    ]);
  }, []);

  return (
    <div className="max-w-full" ref={pageRef}>
      <div className="flex justify-between pb-4">
        <div>
          <h1>My Draft Listings</h1>
        </div>
      </div>

      <div className="flex flex-col items-center max-w-full">
        <TableShadowWrapper>
          <ListingsTable
            tbody={tbody}
            isLoading={isLoading}
            count={data?.count || 0}
          />
        </TableShadowWrapper>
        <CustomPagination
          pageCount={pageCount}
          currentPage={p}
          onPageChange={setPage}
        />
      </div>
    </div>
  );
};

export default PageMyDrafts;
