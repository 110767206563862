import { CustomMarkdownTextareaReadonly } from "@/components/custom-markdown/custom-markdown-textarea-readonly";
import { cilMagnifyingGlass, cilPencil } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CFormTextarea,
  CTab,
  CTabContent,
  CTabList,
  CTabPanel,
  CTabs,
} from "@coreui/react";

type TCustomTextareaProps = {
  divWrap?: boolean;
  withMarkdownPreview?: boolean;
  divWrapProps?: React.ComponentProps<"div">;
} & React.ComponentProps<typeof CFormTextarea>;

const CustomTextarea = (props: TCustomTextareaProps) => {
  const {
    divWrap,
    divWrapProps,
    withMarkdownPreview,
    label,
    id,
    ...propsRest
  } = props;

  if (withMarkdownPreview) {
    return (
      <CTabs activeItemKey={`${id}-edit`}>
        <CTabList
          variant="tabs"
          className="flex justify-between items-end"
          style={{ borderBottom: "none" }}
        >
          {label ? (
            <div>
              <label htmlFor={id} className="form-label">
                {label}
              </label>
            </div>
          ) : null}
          <div className="flex">
            <CTab itemKey={`${id}-edit`}>
              <span className={``}>
                <CIcon icon={cilPencil} /> Write
              </span>
            </CTab>
            <CTab itemKey={`${id}-preview`}>
              <span className={``}>
                <CIcon icon={cilMagnifyingGlass} /> Preview
              </span>
            </CTab>
          </div>
        </CTabList>
        <CTabContent>
          <CTabPanel itemKey={`${id}-edit`}>
            {divWrap ? (
              <div {...divWrapProps}>
                <CFormTextarea {...propsRest} id={id} />
              </div>
            ) : (
              <CFormTextarea {...propsRest} label={label} id={id} />
            )}
          </CTabPanel>
          <CTabPanel itemKey={`${id}-preview`}>
            <CustomMarkdownTextareaReadonly>
              {propsRest.value ? propsRest.value.toString() : ""}
            </CustomMarkdownTextareaReadonly>
          </CTabPanel>
        </CTabContent>
      </CTabs>
    );
  }

  if (divWrap) {
    return (
      <div {...divWrapProps}>
        <CFormTextarea {...propsRest} label={label} id={id} />
      </div>
    );
  }
  return <CFormTextarea {...propsRest} label={label} id={id} />;
};

export default CustomTextarea;
