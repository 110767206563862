import {
  TBreadcrumbItem,
  setBreadcrumbsItems,
} from "@/store/breadcrumbs/slice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

export const useBreadcrumbs = () => {
  const dispatch = useAppDispatch();
  const breadcrumbItems = useAppSelector((state) => state.breadcrumbs.items);

  const setBreadcrumbs = (newItems: TBreadcrumbItem[]) => {
    dispatch(setBreadcrumbsItems(newItems));
  };

  return { breadcrumbItems, setBreadcrumbs };
};
