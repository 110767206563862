import { CustomButton } from "@/components/forms/custom/custom-button/custom-button";
import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import { RequiredAsterist } from "@/components/forms/custom/required-asterisk/required-asterisk";
import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { listingGroupCreateSchema } from "./schema";
import { useCreateListingGroupMutation } from "../../hooks/use-create-listing-group";

const initialValues: TListingGroupCreateForm = {
  name: "",
};

export const CreateGroup = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const createMutation = useCreateListingGroupMutation({
    onSuccess: () => {
      navigate(-1);
    },
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Home",
        link: "/",
      },
      {
        label: "Listing Group",
        link: "/listing-group",
      },
      {
        label: "Create",
        link: "/listing-group/create",
      },
    ]);
  }, []);

  return (
    <div className="max-w-[700px]">
      <Formik<TListingGroupCreateForm>
        initialValues={initialValues}
        validationSchema={listingGroupCreateSchema}
        onSubmit={(values) => {
          createMutation.mutate({
            formData: values,
          });
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
          <Form>
            <div className="flex flex-col">
              <h5 className="px-2 pb-0">New Listing Group</h5>
            </div>

            <div className="h-32 p-3 border border-gray-200 rounded-lg">
              <CustomInput
                className=""
                id="name"
                label={
                  <>
                    Name
                    <RequiredAsterist />
                  </>
                }
                type="text"
                value={values.name}
                onChange={handleChange}
                valid={hasSubmitted && !errors.name}
                invalid={hasSubmitted && !!errors.name}
                feedbackInvalid={errors.name}
                divWrap
              />
            </div>

            <div className="flex justify-end gap-2 pt-4">
              <CustomButton
                type="button"
                color="secondary"
                variant="outline"
                className="flex-1 sm:flex-initial"
                disabled={isSubmitting}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </CustomButton>
              <CustomButton
                type="button"
                color="info"
                className="flex-1 text-white sm:flex-initial"
                disabled={isSubmitting}
                onClick={() => {
                  // if (state.isSubmitting) return;
                  // setHasSubmitted(true);
                  setHasSubmitted(true);
                  handleSubmit();
                }}
              >
                Create
              </CustomButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export type TListingGroupCreateForm = {
  name: string;
};
