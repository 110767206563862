import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { DiscoverTable } from "../components/discover-table";
import { NewDiscoverCarousel } from "../components/new-discover-carousel";
import { useEffect } from "react";

export const PageDiscover = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        link: "/",
        label: "Home",
      },
      {
        label: "Discover",
        link: "/discover",
      },
    ]);
  }, []);

  return (
    <div>
      <NewDiscoverCarousel />
      <div className="my-4 border-b-2" />
      <DiscoverTable />
    </div>
  );
};
