import { EListingCategory } from "@/enums/listing-enums";
import { CBadge } from "@coreui/react";

type TCategoryBadgeProps = {
  category?: EListingCategory;
};

export const CategoryBadge = (props: TCategoryBadgeProps) => {
  const { category = "" } = props;

  let text: string = category;

  switch (category) {
    case EListingCategory.CNC:
      text = "CNC";
      break;
    case EListingCategory.WOOD_MACHINERY:
      text = "Wood Machinery";
      break;
    case EListingCategory.WOOD_SCANNERS:
      text = "Wood Scanners";
      break;
    case EListingCategory.FURNITURE_MACHINERY:
      text = "Furniture Machinery";
      break;
    case EListingCategory.METAL_MACHINERY:
      text = "Metal Machinery";
      break;
    case EListingCategory.SOFTWARE:
      text = "Software";
      break;
    case EListingCategory.OTHER_EQUIPMENT:
      text = "Other Equipment";
      break;
    default:
      break;
  }

  return <CBadge color="secondary">{text}</CBadge>;
};
