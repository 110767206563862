import { AttachmentTable } from "@/components/attachments/attachment-table/attachment-table";
import { CustomFileInput } from "@/components/attachments/custom-file-input/custom-file-input";
import {
  MAX_ATTACHMENT_SIZE,
  MAX_ATTACHMENTS,
} from "@/constants/form-constatns";
import { integerToFilesize } from "@/utils/integer-to-filesize";
import { cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CTooltip } from "@coreui/react";
import { ChangeEvent, useEffect, useRef } from "react";

type TListingCreateAttachmentsProps = {
  files: File[];
  pageRef: React.RefObject<HTMLDivElement>;
  handleAttachmentToDelete: ({ indexOfNew }: { indexOfNew?: number }) => void;
  handlerFilesOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const ListingCreateAttachments = ({
  files,
  pageRef,
  handleAttachmentToDelete,
  handlerFilesOnChange,
}: TListingCreateAttachmentsProps) => {
  const fileInputFormRef = useRef<HTMLFormElement>(null);

  const isTooManyEstimatedAttachments = files.length > MAX_ATTACHMENTS;

  useEffect(() => {
    if (files.length === 0) {
      fileInputFormRef.current?.reset();
    }
  }, [files]);

  return (
    <>
      <div className="flex flex-col items-center w-full">
        <span
          className={`${isTooManyEstimatedAttachments ? "text-red-500" : ""}`}
        >
          Listings can have no more than{" "}
          <span className="font-bold">{MAX_ATTACHMENTS}</span> attachments.
        </span>
        {isTooManyEstimatedAttachments ? (
          <span className="text-red-500">
            Please remove to make it 5 or less.
          </span>
        ) : null}
        <span>
          Each attachment must be of size less than{" "}
          <span className="font-bold">
            {integerToFilesize(MAX_ATTACHMENT_SIZE)}
          </span>
          .
        </span>
      </div>

      <AttachmentTable
        isEditing={true}
        isRed={isTooManyEstimatedAttachments}
        attachments={
          [
            ...files.map((file, index) => {
              return {
                indexOfNew: index,
                display_name: file.name,
                attachment_url: URL.createObjectURL(file),
                size: file.size,
                created_at: new Date().toISOString(),
                created_by: "You",
              };
            }),
          ].map((file) => {
            const splitName = file?.display_name.split(".");
            const type = splitName[splitName.length - 1] || "";

            return {
              fileUrl: file?.attachment_url,
              name: file?.display_name,
              size: integerToFilesize(file?.size),
              type,
              uploadedAt: file?.created_at,
              uploadedBy: file?.created_by,
              markedToDelete: false,
              indexOfNew: file?.indexOfNew ?? null,
              actions: (
                <div className="flex justify-center w-auto gap-1">
                  <CTooltip content={"Remove"} container={pageRef.current}>
                    <CButton
                      color={"danger"}
                      size="sm"
                      onClick={() => {
                        handleAttachmentToDelete({
                          indexOfNew:
                            file?.indexOfNew !== null &&
                            file?.indexOfNew !== undefined
                              ? file.indexOfNew
                              : undefined,
                        });
                      }}
                    >
                      <span className="text-white">
                        <CIcon icon={cilTrash} />
                      </span>
                    </CButton>
                  </CTooltip>
                </div>
              ),
            };
          }) || []
        }
        isWithActions
      />

      <form ref={fileInputFormRef}>
        <CustomFileInput
          id="file"
          multiple
          isEditing
          files={files}
          onChange={handlerFilesOnChange}
          rightSide={
            <span>
              Listing will have{" "}
              <span className="font-bold">{files.length}</span> attachments
            </span>
          }
          rightSideIsError={files.length > MAX_ATTACHMENTS}
        />
      </form>
    </>
  );
};
