import { CategoryBadge } from "@/components/badges/category-badge";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { EListingCategory } from "@/enums/listing-enums";
import { authedFetch } from "@/utils/authed-fetch";
import { dateToYearMonthDay } from "@/utils/date-fns";
import { useAuth } from "@clerk/clerk-react";
import { cilChevronLeft, cilChevronRight } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CTooltip,
} from "@coreui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const VISIBLE_CARDS = 3;

type TListing = {
  id: number;
  title: string;
  category: string;
  country: string;
  created_at: string;
  description: string;
  deadline: string;
};

type TQueryResponse = {
  count: number;
  listings: TListing[];
};

export const NewDiscoverCarousel = () => {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery<TQueryResponse>({
    queryKey: ["new-listings"],
    queryFn: async () => {
      const response = await authedFetch({
        endpoint: "api/provider/discover/latest",
        token: await getToken(),
      });
      return response.json();
    },
  });

  const listingsMap: React.ReactNode[] =
    data?.listings?.map((listing) => (
      <CCard style={{ width: "100%", maxHeight: "300px" }}>
        <CCardBody className="flex flex-col">
          <CCardTitle>
            <div className="text-sm text-slate-500">#{listing.id}</div>
            {listing.title}
            <div className="pb-2 text-sm">
              <CategoryBadge category={listing.category as EListingCategory} />
            </div>
          </CCardTitle>
          <CTooltip
            content={listing.description}
            style={
              {
                "--cui-tooltip-bg": "var(--cui-secondary)",
              } as any
            }
          >
            <CCardText className="flex flex-col flex-1">
              <span className="flex-1 p-1 overflow-hidden rounded-sm border-1 max-h-24">
                <span className="text-sm">{listing?.description}</span>
              </span>
            </CCardText>
          </CTooltip>
          <div className="flex items-end justify-between">
            <div className="flex flex-col text-sm">
              <span className="font-bold">Available until:</span>
              <span>
                {listing?.deadline ? dateToYearMonthDay(listing.deadline) : "-"}
              </span>
            </div>

            <CButton
              color="info"
              className="text-white"
              onClick={() => {
                navigate(`/discover/${listing.id}`, {
                  state: "discover-preview",
                });
              }}
            >
              View More
            </CButton>
          </div>
        </CCardBody>
      </CCard>
    )) || [];

  if (!isLoading && listingsMap.length === 0) {
    return <div>No data.</div>;
  }

  const skeletonsMap = Array.from({ length: VISIBLE_CARDS }).map((_) => (
    <CCard style={{ width: "100%", maxHeight: "300px", minHeight: "275px" }}>
      <CCardBody className="flex flex-col">
        <CCardTitle>
          <div className="text-sm text-slate-500">
            <DefaultSkeleton className="w-12" />
          </div>
          <DefaultSkeleton className="w-40" />
          <div className="pb-2 text-sm">
            <DefaultSkeleton className="w-16" />
          </div>
        </CCardTitle>

        {/* <CCardText className="flex flex-col flex-1"> */}
        <span className="flex-1 p-1 overflow-hidden rounded-sm border-1 max-h-24">
          <span className="text-sm">
            <DefaultSkeleton />
          </span>
        </span>
        {/* </CCardText> */}

        <div className="flex items-end justify-between">
          <div className="flex flex-col text-sm">
            <DefaultSkeleton className="w-24" classNameInner="h-4" />
            <DefaultSkeleton className="w-20" classNameInner="h-4 my-0" />
          </div>

          <DefaultSkeleton className="w-24" classNameInner="h-8 my-0" />
        </div>
      </CCardBody>
    </CCard>
  ));

  return (
    <div>
      <Carousel cards={isLoading ? skeletonsMap : listingsMap} />
    </div>
  );
};

const Carousel = ({ cards }: { cards: React.ReactNode[] }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollNext = () => {
    setScrollPosition((prev) =>
      Math.min(prev + 1, cards?.length - VISIBLE_CARDS)
    );
  };

  const scrollPrev = () => {
    setScrollPosition((prev) => Math.max(prev - 1, 0));
  };

  return (
    <>
      <h3 className="flex items-center">
        <span>Latest listings</span>
      </h3>
      <div className="relative flex items-center px-12">
        <button
          className="absolute left-0 z-10 flex items-center justify-center p-2 transition-all rounded-full disabled:opacity-50 hover:drop-shadow-md"
          onClick={scrollPrev}
          disabled={scrollPosition === 0}
        >
          <CIcon icon={cilChevronLeft} className="text-info" size="xxl" />
        </button>

        <div className="relative flex items-center w-full overflow-hidden">
          <div className="w-full overflow-hidden">
            <div
              className="flex transition-transform duration-300"
              style={{
                transform: `translateX(-${scrollPosition * (100 / VISIBLE_CARDS)}%)`,
              }}
            >
              {cards.map((card, index) => (
                <div
                  className="flex-none"
                  style={{ width: `${100 / VISIBLE_CARDS}%` }}
                  key={index}
                >
                  <div className="flex items-stretch justify-center h-full p-2 ">
                    {card}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <button
          className="absolute right-0 z-10 flex items-center justify-center p-2 transition-all rounded-full disabled:opacity-50 hover:drop-shadow-md"
          onClick={scrollNext}
          disabled={scrollPosition === cards.length - VISIBLE_CARDS}
        >
          <CIcon icon={cilChevronRight} className="text-info" size="xxl" />
        </button>
      </div>
    </>
  );
};
