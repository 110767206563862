import { CFormInput, CInputGroup, CInputGroupText } from "@coreui/react";
import { useRef } from "react";

type TCustomFileInputProps = React.ComponentProps<typeof CFormInput> & {
  showPreview?: boolean;
  files?: File[];
  divWrap?: boolean;
  divWrapProps?: React.ComponentProps<"div">;
  isEditing?: boolean;
  rightSide?: React.ReactNode;
  rightSideIsError?: boolean;
};

export const CustomFileInput = (props: TCustomFileInputProps) => {
  const {
    showPreview,
    files,
    label,
    multiple,
    className,
    isEditing,
    rightSide,
    rightSideIsError,
    ...propsRest
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  let inputText = "No file chosen";

  if (files && files.length) {
    inputText = files.length > 1 ? `${files.length} files` : files[0].name;
  }

  if (isEditing) {
    return (
      <div>
        <CFormInput
          ref={inputRef}
          type="file"
          accept="image/*,.pdf,.txt,.doc,.docx"
          multiple={multiple}
          {...propsRest}
          style={{ display: "none" }}
        />
        <CInputGroup
          className="cursor-pointer w-ful"
          onClick={() => inputRef.current?.click()}
        >
          <CInputGroupText>Choose Files</CInputGroupText>
          <CFormInput
            value={inputText}
            onFocus={(e) => e.target.blur()}
            className="cursor-pointer "
          />
          {rightSide !== undefined ? (
            <CInputGroupText
              style={{
                backgroundColor: rightSideIsError ? "#ef4444" : undefined,
                color: rightSideIsError ? "#fff" : undefined,
              }}
            >
              {rightSide}
            </CInputGroupText>
          ) : null}
        </CInputGroup>
      </div>
    );
  }

  return null;
};
