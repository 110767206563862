import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import { RequiredAsterist } from "@/components/forms/custom/required-asterisk/required-asterisk";
import { TListingEditForm } from "../listing-view-edit";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";

type TListingViewLocationProps = {
  valueCountry: TListingEditForm["country"];
  valueRegion: TListingEditForm["region"];
  errorCountry?: string;
  errorRegion?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasSubmittedPublish: boolean;
  hasSubmittedDraft: boolean;
  isEditing: boolean;
  isLoading?: boolean;
};

const titleCountry = "Country";
const titleRegion = "Region";

export const ListingViewLocation = (props: TListingViewLocationProps) => {
  const {
    valueCountry,
    valueRegion,
    handleChange,
    hasSubmittedPublish,
    hasSubmittedDraft,
    isEditing,
    isLoading,
    errorCountry,
    errorRegion,
  } = props;

  if (isLoading) {
    return (
      <div className="flex w-full gap-2 p-3 border border-gray-200 rounded-lg">
        <div className="flex-1">
          <label className="form-label">{titleCountry}</label>
          <DefaultSkeleton
            className="px-[0px] py-[0px] max-w-full"
            classNameInner="h-[30px] my-[4px]"
          />
        </div>
        <div className="flex-1">
          <label className="form-label">{titleRegion}</label>
          <DefaultSkeleton
            className="px-[0px] py-[0px] max-w-full"
            classNameInner="h-[30px] my-[4px]"
          />
        </div>
      </div>
    );
  }

  if (!isEditing) {
    return (
      <div className="flex w-full gap-2 p-3 border border-gray-200 rounded-lg">
        <div className="flex-1">
          <label className="form-label">{titleCountry}</label>
          <p className="px-[12px] py-[6px] mb-0 border rounded min-h-[38px]">
            {valueCountry}
          </p>
        </div>
        <div className="flex-1">
          <label className="form-label">{titleRegion}</label>
          <p className="px-[12px] py-[6px] mb-0 border rounded min-h-[38px]">
            {valueRegion}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-2 p-3 border border-gray-200 rounded-lg">
      <CustomInput
        id="country"
        type="text"
        label={
          <>
            {titleCountry}
            <RequiredAsterist />
          </>
        }
        placeholder={titleCountry}
        value={valueCountry}
        onChange={handleChange}
        valid={
          !errorCountry &&
          (hasSubmittedPublish || (hasSubmittedDraft && !!valueCountry))
        }
        invalid={
          !!errorCountry &&
          (hasSubmittedPublish || (hasSubmittedDraft && !!valueCountry))
        }
        feedbackInvalid={errorCountry}
        divWrap
        divWrapProps={{
          className: "sm:flex-1",
        }}
      />
      <CustomInput
        id="region"
        type="text"
        label={<>{titleRegion}</>}
        value={valueRegion}
        onChange={handleChange}
        valid={
          !errorRegion &&
          (hasSubmittedPublish || (hasSubmittedDraft && !!valueRegion))
        }
        invalid={
          !!errorRegion &&
          (hasSubmittedPublish || (hasSubmittedDraft && !!valueRegion))
        }
        feedbackInvalid={errorRegion}
        divWrap
        divWrapProps={{
          className: "sm:flex-1",
        }}
      />
    </div>
  );
};
