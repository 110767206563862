import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import { SortArrow } from "@/components/forms/custom/custom-table/sort-arrow";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { useDebounce } from "@/hooks/use-debounce";
import { authedFetch } from "@/utils/authed-fetch";
import { dateToYearMonthDay } from "@/utils/date-fns";
import { useAuth } from "@clerk/clerk-react";
import {
  cilBan,
  cilCalendar,
  cilFile,
  cilPen,
  cilSearch,
  cilSettings,
  cilUser,
  cilX,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CBadge,
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
  CTooltip,
} from "@coreui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GroupsAndListingsButtons } from "../components/groups-and-listings-buttons";
import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { setModalConfirmation } from "@/store/modal/slice";
import { useAppDispatch } from "@/store/hooks";
import { TableShadowWrapper } from "@/components/forms/custom/custom-table/table-shadow-wrapper";
import { CustomPagination } from "@/components/forms/custom/custom-pagination/custom-pagination";
import { useDeleteListingGroupMutation } from "../hooks/use-delete-listing-group-mutation";

type TQuery = {
  groups: {
    id: number;
    name: string;
    created_at: string;
  }[];
  count: number;
};

type TColumn = {
  key: string;
  label: React.ReactNode;
};

type TSpreadableParams = {
  p?: string;
  ps?: string;
  orderBy?: string;
  orderDir?: string;
  name?: string;
};

export const ListingGroup = () => {
  const { getToken } = useAuth();
  const [mouseOverIndex, setMouseOverIndex] = useState<number | null>(null);
  const pageRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const p = parseInt(searchParams.get("p") || "1");
  const ps = parseInt(searchParams.get("ps") || "0");
  const orderBy = searchParams.get("orderBy") || "";
  const orderDir = searchParams.get("orderDir") || "";
  const nameRaw = searchParams.get("name") || "";
  const setPage = (page: number) => setSearchParams({ p: page.toString() });

  const spreadableParams: TSpreadableParams = {};
  if (p && p <= 1) spreadableParams.p = p.toString();
  if (ps) spreadableParams.ps = ps.toString();
  if (orderBy) spreadableParams.orderBy = orderBy;
  if (orderDir) spreadableParams.orderDir = orderDir;
  if (nameRaw) spreadableParams.name = nameRaw;

  const name = useDebounce(nameRaw, 250);

  const { data, isLoading, refetch } = useQuery<TQuery>({
    queryKey: ["listing-group", p, ps, name, orderBy, orderDir],
    queryFn: async ({ queryKey }) => {
      const [_, p, ps, name, orderBy, orderDir] = queryKey;

      const pParam = p ? `p=${p}` : "";
      const psParam = ps ? `ps=${ps}` : "";
      const nameParam = name ? `name=${name}` : "";
      const orderByParam = orderBy ? `orderBy=${orderBy}` : "";
      const orderDirParam = orderDir ? `orderDir=${orderDir}` : "";
      const params =
        p || ps || name || orderBy || orderDir
          ? `?${[pParam, psParam, nameParam, orderByParam, orderDirParam].filter((param) => param).join("&")}`
          : "";
      return authedFetch({
        endpoint: `api/office/listing-group${params}`,
        token: await getToken(),
      }).then((res) => res.json());
    },
  });

  const pageCount = Math.ceil((data?.count || 0) / 10);

  const count = data?.count || 0;

  const deleteMutation = useDeleteListingGroupMutation({ refetch });

  const handleDelete = (id: number) => {
    deleteMutation.mutate({ id });
  };

  const dispatchDeleteModal = ({ id, title }: { id: number; title: string }) =>
    setModalConfirmation({
      isOpen: true,
      title: "Delete Listing",
      message: "Are you sure you want to delete this listing?",
      messageSecondary: `#${id}: ${title}`,
      buttons: {
        cancel: {
          label: "Cancel",
          color: "dark",
          variant: "outline",
        },
        confirm: {
          label: "Delete",
          color: "danger",
          onClick: () => handleDelete(id),
        },
      },
    });

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setSearchParams({ ...spreadableParams, name: e.target.value });
    } else {
      delete spreadableParams.name;
      setSearchParams({ ...spreadableParams });
    }
  };

  const setOrder = (orderBy: string, orderDir: string) => {
    setSearchParams({ ...spreadableParams, orderBy, orderDir });
  };

  const handleClearFilterBadge = (key: keyof TSpreadableParams) => {
    delete spreadableParams[key];
    setSearchParams({ ...spreadableParams });
  };
  const handleClearFilterAll = () => {
    delete spreadableParams.name;
    setSearchParams({
      ...spreadableParams,
    });
  };

  const sortArrow = (type: string) => {
    return (
      <SortArrow
        columnName={type}
        orderBy={orderBy}
        orderDir={orderDir}
        onClick={setOrder}
      />
    );
  };

  const columns: TColumn[] = [
    {
      key: "id",
      label: (
        <div className="flex items-center h-full gap-1">
          <span>#</span>
          {sortArrow("id")}
        </div>
      ),
    },
    {
      key: "name",
      label: (
        <div className="flex items-center h-full gap-1">
          <CIcon icon={cilFile} /> Name
          {sortArrow("name")}
        </div>
      ),
    },
    {
      key: "created_by",
      label: (
        <div className="flex items-center h-full gap-1 whitespace-nowrap">
          <CIcon icon={cilUser} /> Created By
        </div>
      ),
    },
    {
      key: "created_at",
      label: (
        <div className="flex items-center h-full gap-1 whitespace-nowrap">
          <CIcon icon={cilCalendar} /> Created At
          {sortArrow("created_at")}
        </div>
      ),
    },
    {
      key: "actions",
      label: (
        <div className="flex items-center h-full gap-1 whitespace-nowrap">
          <CIcon icon={cilSettings} /> Actions
        </div>
      ),
    },
  ] as const;

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Home",
        link: "/",
      },
      {
        label: "Listing Groups",
        link: "/listing-group",
      },
    ]);
  }, []);

  return (
    <div ref={pageRef}>
      <GroupsAndListingsButtons activeState="groups" />

      <div className="flex justify-between pt-3 pb-2">
        <div>
          <h1>Listing Groups</h1>
        </div>
        <div>
          <CButton
            color="info"
            onClick={() => {
              navigate("/listing-group/create");
            }}
          >
            <span className="text-white">
              <CIcon icon={cilPen} />
              <span className="ml-2">Create Group</span>
            </span>
          </CButton>
        </div>
      </div>

      <div className="flex items-center gap-3 px-3 py-3 bg-white rounded-t-xl">
        <div className="flex items-center gap-2">
          <span className="font-bold">Name: </span>
          <div>
            <CustomInput
              placeholder="Search..."
              value={nameRaw}
              onChange={handleChangeName}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setSearchParams({ ...spreadableParams, search: "" });
                }
              }}
              divWrap
              clearable
            />
          </div>
        </div>
      </div>
      {name ? (
        <div className="flex px-3 pb-3 bg-white">
          <div className="flex items-stretch gap-2 p-2 bg-gray-100 rounded-lg">
            {name && (
              <CBadge color="info">
                <span className="flex items-center gap-1">
                  {`Name: ${name}`}
                  <CIcon
                    icon={cilX}
                    size="sm"
                    onClick={() => handleClearFilterBadge("name")}
                    className="cursor-pointer"
                  />
                </span>
              </CBadge>
            )}
            <CBadge color="danger">
              <CIcon
                icon={cilX}
                size="sm"
                onClick={() => handleClearFilterAll()}
                className="cursor-pointer"
              />
            </CBadge>
          </div>
        </div>
      ) : null}
      <div className="self-stretch h-[5px] bg-white mb-[-5px]" />
      <TableShadowWrapper>
        <CTable
          style={{
            marginBottom: 0,
          }}
          className={`bg-white shadow-lg rounded-lg ${false ? "opacity-75" : ""} transition-all`}
        >
          <CTableBody
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr auto auto auto",
            }}
            onMouseLeave={() => {
              setMouseOverIndex(() => null);
            }}
          >
            <CTableRow
              className="text-base font-extrabold tracking-wider text-gray-900 uppercase bg-gray-200"
              style={{
                display: "contents",
              }}
              onMouseOver={() => {
                setMouseOverIndex(() => null);
              }}
            >
              {columns.map((column) => (
                <CTableHeaderCell
                  className="py-4 border-b-2 border-gray-400"
                  scope="col"
                  key={column.key}
                >
                  {column.label}
                </CTableHeaderCell>
              ))}
            </CTableRow>
            {isLoading
              ? columns.map((_) => (
                  <CTableDataCell>
                    <DefaultSkeleton />
                  </CTableDataCell>
                ))
              : data?.groups?.map?.((item: any, _index: number) => (
                  <CTableRow
                    className="text-gray-700 hover:bg-gray-100"
                    key={item.id}
                    style={{
                      display: "contents",
                    }}
                    onMouseOver={() => {
                      setMouseOverIndex(() => _index);
                    }}
                    color={_index === mouseOverIndex ? "info" : ""}
                  >
                    <CTableDataCell className="flex items-center">
                      {item?.id}
                    </CTableDataCell>
                    <CTableDataCell className="flex items-center">
                      {item?.name}
                    </CTableDataCell>
                    <CTableDataCell className="flex items-center">
                      {item?.created_by}
                    </CTableDataCell>
                    <CTableDataCell className="flex items-center">
                      {dateToYearMonthDay(item?.created_at)}
                    </CTableDataCell>
                    <CTableDataCell>
                      <div className="flex w-auto gap-1">
                        <CTooltip content="View" container={pageRef.current}>
                          <CButton
                            color="info"
                            type="button"
                            onClick={() => {
                              navigate(`/listing-group/${item.id}`);
                            }}
                          >
                            <span className="text-white">
                              <CIcon icon={cilSearch} />
                            </span>
                          </CButton>
                        </CTooltip>

                        <CTooltip content="Remove" container={pageRef.current}>
                          <CButton
                            color="danger"
                            onClick={() => {
                              dispatch(
                                dispatchDeleteModal({
                                  id: item.id,
                                  title: item.title,
                                })
                              );
                            }}
                          >
                            <span className="text-white">
                              <CIcon icon={cilBan} />
                            </span>
                          </CButton>
                        </CTooltip>
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                ))}
          </CTableBody>
        </CTable>
      </TableShadowWrapper>

      <div className="flex justify-center">
        <CustomPagination
          pageCount={pageCount}
          currentPage={p}
          onPageChange={setPage}
        />
      </div>

      {!isLoading && count === 0 && (
        <div className="flex flex-col items-center justify-center h-64">
          <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg shadow-lg">
            <p className="text-xl font-bold text-gray-700">No Data Available</p>
            <p className="mt-2 text-center text-gray-500">
              There is currently no data to display. Create your first item!
            </p>
            <p className="mt-2 text-center text-gray-500">
              In case of issues, reach out to{" "}
              <a
                href="mailto:support@machinevertical.com"
                className="text-blue-500 underline"
              >
                support@machinevertical.com
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
