import CustomTextarea from "@/components/forms/custom/custom-textarea/custom-textarea";
import { TListingEditForm } from "../listing-view-edit";
import { RequiredAsterist } from "@/components/forms/custom/required-asterisk/required-asterisk";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { CustomMarkdownTextareaReadonly } from "@/components/custom-markdown/custom-markdown-textarea-readonly";

type TListingViewSpecificationsProps = {
  valueReqiredSpefification: TListingEditForm["specification"];
  valueOptionalSpecification: TListingEditForm["optional_specification"];
  errorRequiredSpecification?: string;
  errorOptionalSpecification?: string;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  hasSubmittedDraft: boolean;
  hasSubmittedPublish: boolean;
  hasSubmittedEither: boolean;
  isEditing: boolean;
  isLoading?: boolean;
};

const titleRequired = "Required Specifications";
const titleOptional = "Optional Specifications";

export const ListingViewSpecifications = (
  props: TListingViewSpecificationsProps
) => {
  const {
    valueOptionalSpecification,
    valueReqiredSpefification,
    errorOptionalSpecification,
    errorRequiredSpecification,
    handleChange,
    hasSubmittedDraft,
    hasSubmittedPublish,
    hasSubmittedEither,
    isEditing,
    isLoading,
  } = props;

  if (isLoading) {
    return (
      <>
        <div className="mb-4">
          <label className="form-label pt-[9px]">{titleRequired}</label>
          <DefaultSkeleton
            className="px-[0px] py-[0px] max-w-full h-[62px]"
            classNameInner="h-full rounded-lg mt-0 mb-0"
          />
        </div>
        <div className="mb-4">
          <label className="form-label pt-[9px]">{titleOptional}</label>
          <DefaultSkeleton
            className="px-[0px] py-[0px] max-w-full h-[62px] "
            classNameInner="h-full rounded-lg mt-0 mb-0"
          />
        </div>
      </>
    );
  }

  if (!isEditing) {
    return (
      <>
        <div className="mb-4">
          <label className="form-label pt-[9px]">{titleRequired}</label>
          <p className="px-[12px] py-[6px] border rounded min-h-[62px] mb-0">
            {valueReqiredSpefification}
          </p>
        </div>
        <div className="mb-4">
          <label className="form-label pt-[9px]">{titleOptional}</label>
          {/* <p className="px-[12px] py-[6px] border rounded min-h-[62px] mb-0">
            {valueOptionalSpecification}
          </p> */}
          <CustomMarkdownTextareaReadonly>
            {valueOptionalSpecification ? valueOptionalSpecification : ""}
          </CustomMarkdownTextareaReadonly>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="mb-4">
        <CustomTextarea
          id="specification"
          label={
            <>
              {titleRequired}
              <RequiredAsterist />
            </>
          }
          value={valueReqiredSpefification}
          onChange={handleChange}
          valid={
            !errorRequiredSpecification &&
            (hasSubmittedPublish ||
              (hasSubmittedDraft && !!valueReqiredSpefification))
          }
          invalid={
            !!errorRequiredSpecification &&
            (hasSubmittedPublish ||
              (hasSubmittedDraft && !!valueReqiredSpefification))
          }
          feedbackInvalid={errorRequiredSpecification}
          divWrap
          withMarkdownPreview
        />
      </div>

      <div className="mb-4">
        <CustomTextarea
          id="optional_specification"
          label={<>{titleOptional}</>}
          value={valueOptionalSpecification}
          onChange={handleChange}
          valid={
            !errorOptionalSpecification &&
            (hasSubmittedPublish ||
              (hasSubmittedDraft && !!valueOptionalSpecification))
          }
          invalid={!!errorOptionalSpecification && hasSubmittedEither}
          feedbackInvalid={errorOptionalSpecification}
          divWrap
          withMarkdownPreview
        />
      </div>
    </>
  );
};
