import { authedFetch } from "@/utils/authed-fetch";
import { TListingCreateForm } from "./listing-create";

type TCreateListingFetcherProps = {
  formData: TListingCreateForm;
  files?: File[];
  status: "OPEN" | "DRAFT";
  token: string | null;
};

export const createListingFetcher = async (
  props: TCreateListingFetcherProps
) => {
  const { formData, status, token, files } = props;

  const listingRes = await authedFetch({
    endpoint: "api/office/listing",
    method: "POST",
    body: {
      group_id: formData.group_id,
      title: formData.title,
      description: formData.description,
      category: formData.category,
      status: status,
      specification: formData.specification,
      optional_specification: formData.optional_specification,
      is_hidden: formData.is_hidden,
      budget: formData.budget,
      setup: {
        participants: formData.participants,
        deadline: formData.deadline,
      },
      location: {
        country: formData.country,
        region: formData.region,
      },
    },
    token: token,
  }).then((res) => {
    if (res.status >= 400) {
      throw new Error("Failed to create listing");
    }
    return res.json();
  });

  const listingId = listingRes?.repoRes?.id;

  if (!files || !files?.length || !listingId) {
    return listingRes;
  }

  const attachmentFormData = new FormData();
  files.forEach((file) => {
    attachmentFormData.append("file", file);
  });

  // @ts-ignore
  const endpoint = import.meta.env.VITE_API_ENDPOINT;
  const attachmentsRes = await fetch(
    `${endpoint}/api/office/listing/attachment/${formData.group_id}/${listingRes?.repoRes?.id}`,
    {
      method: "POST",
      body: attachmentFormData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    if (res.status >= 400) {
      throw new Error("Failed to upload attachments");
    }
  });

  return Promise.all([listingRes, attachmentsRes]);
};
