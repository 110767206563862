export enum EUserAccountType {
  OFFICE_USER = "OFFICE_USER",
  EQUIPMENT_PROVIDER = "EQUIPMENT_PROVIDER",
}

export enum EUserContactInfoType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export enum EUserEPIndustryType {
  FURNITURE = "FURNITURE",
  WINDOWS = "WINDOWS",
  HOUSE_PARTS = "HOUSE_PARTS",
  TEXTILE = "TEXTILE",
}
