import { DefaultSpinner } from "@/components/loaders/default-spinner";
import { EEnrollmentMyEnrollmentsFilters } from "@/enums/enrollment-enums";
import { CCard } from "@coreui/react";
import { ComponentProps } from "react";

type TPageEnrollmentsStatusCardsProps = {
  onStatusChange: (status: EEnrollmentMyEnrollmentsFilters | "") => void;
  counts: {
    initiated: number;
    offered: number;
    agreed: number;
    closed: number;
  };
  status?: EEnrollmentMyEnrollmentsFilters | "";
  isLoading?: boolean;
};

type TStatusCard = {
  color: ComponentProps<typeof CCard>["color"];
  amount: number;
  title: string;
  active?: boolean;
  onClick: () => void;
  isLoading?: boolean;
};

export const PageEnrollmentsStatusCards = (
  props: TPageEnrollmentsStatusCardsProps
) => {
  const { status, onStatusChange, counts, isLoading } = props;

  return (
    <div className="flex flex-col flex-wrap w-full gap-4 pb-4 sm:flex-row justify-stretch sm:gap-6">
      <StatusCard
        color="primary"
        amount={counts.initiated}
        title="Initiated"
        active={status === EEnrollmentMyEnrollmentsFilters.INITIATED}
        isLoading={isLoading}
        onClick={() => {
          onStatusChange(
            status === EEnrollmentMyEnrollmentsFilters.INITIATED
              ? ""
              : EEnrollmentMyEnrollmentsFilters.INITIATED
          );
        }}
      />
      <StatusCard
        color="info"
        amount={counts.offered}
        title="Offered"
        active={status === EEnrollmentMyEnrollmentsFilters.OFFERED}
        isLoading={isLoading}
        onClick={() => {
          onStatusChange(
            status === EEnrollmentMyEnrollmentsFilters.OFFERED
              ? ""
              : EEnrollmentMyEnrollmentsFilters.OFFERED
          );
        }}
      />
      <StatusCard
        color="warning"
        amount={counts.agreed}
        title="Agreed"
        active={status === EEnrollmentMyEnrollmentsFilters.AGREED}
        isLoading={isLoading}
        onClick={() => {
          onStatusChange(
            status === EEnrollmentMyEnrollmentsFilters.AGREED
              ? ""
              : EEnrollmentMyEnrollmentsFilters.AGREED
          );
        }}
      />
      <StatusCard
        color="danger"
        amount={counts.closed}
        title="Closed"
        active={status === EEnrollmentMyEnrollmentsFilters.CLOSED}
        isLoading={isLoading}
        onClick={() => {
          onStatusChange(
            status === EEnrollmentMyEnrollmentsFilters.CLOSED
              ? ""
              : EEnrollmentMyEnrollmentsFilters.CLOSED
          );
        }}
      />
    </div>
  );
};

const StatusCard = (props: TStatusCard) => {
  const { color, amount, title, active, onClick, isLoading } = props;
  let selectedDropShadow = "";
  let gradient = "";
  let activeGradient = "";

  // const generateShadows = (rgb: string) =>
  //   `shadow-[5px_5px_rgba(${rgb},_0.6),_10px_10px_rgba(${rgb},_0.4),_15px_15px_rgba(${rgb},_0.2)] scale-105`;

  switch (color) {
    case "primary":
      selectedDropShadow = `shadow-[5px_5px_rgba(_88,_86,_214,_0.6),_10px_10px_rgba(_88,_86,_214,_0.4),_15px_15px_rgba(_88,_86,_214,_0.2)] scale-105`;
      gradient = `from-indigo-600 to-indigo-600/50`;
      activeGradient = `from-indigo-600 via-indigo-600 to-indigo-600/80`;
      break;
    case "info":
      selectedDropShadow = `shadow-[5px_5px_rgba(_51,_153,_255,_0.6),_10px_10px_rgba(_51,_153,_255,_0.4),_15px_15px_rgba(_51,_153,_255,_0.2)] scale-105`;
      gradient = `from-blue-500 to-blue-500/50`;
      activeGradient = `from-blue-500 via-blue-500 to-blue-500/80`;
      break;
    case "warning":
      selectedDropShadow = `shadow-[5px_5px_rgba(_249,_177,_21,_0.6),_10px_10px_rgba(_249,_177,_21,_0.4),_15px_15px_rgba(_249,_177,_21,_0.2)] scale-105`;
      gradient = `from-yellow-500 to-yellow-500/50`;
      activeGradient = `from-yellow-500 via-yellow-500 to-yellow-500/80`;
      break;
    case "danger":
      selectedDropShadow = `shadow-[5px_5px_rgba(_229,_83,_83,_0.6),_10px_10px_rgba(_229,_83,_83,_0.4),_15px_15px_rgba(_229,_83,_83,_0.2)] scale-105`;
      gradient = `from-red-500 to-red-500/50`;
      activeGradient = `from-red-500 via-red-500 to-red-500/80`;
  }

  const dropShadowActive = "drop-shadow-[2px_2px_rgba(0,0,0,_0.6)]";
  const dropShadowInactive = "drop-shadow-[1px_1px_rgba(0,0,0,_0.6)]";

  return (
    <CCard
      onClick={onClick}
      className={`flex-1 transition-all sm:hover:scale-105 ${active ? selectedDropShadow : ""} cursor-pointer overflow-hidden`}
    >
      {/* MOBILE */}
      <div
        className={`flex items-center justify-between px-3 pt-2 pb-1 sm:hidden ${active ? activeGradient : gradient}`}
      >
        <h1
          className={`text-white whitespace-nowrap transition-all ${active ? dropShadowActive : dropShadowInactive}`}
        >
          {title}
        </h1>
        <h3
          className={`text-white transition-all ${active ? dropShadowActive : dropShadowInactive}`}
        >
          {amount}
        </h3>
      </div>
      {/* DESKTOP */}
      <div
        className={`flex-col hidden px-3 py-3 sm:flex transition-all bg-gradient-to-tr ${active ? activeGradient : gradient}`}
      >
        <h3
          className={`text-white h-9 transition-all ${active ? dropShadowActive : dropShadowInactive}`}
        >
          {isLoading ? (
            <DefaultSpinner color="white" className="inline-block scale-75" />
          ) : (
            amount
          )}
        </h3>

        <h1
          className={`text-white whitespace-nowrap transition-all ${active ? dropShadowActive : dropShadowInactive}`}
        >
          {title}
        </h1>
      </div>
    </CCard>
  );
};
