import { createRoot } from "react-dom/client";
import "core-js";
import "./index.css";

import App from "./app";
import { CustomClerkProvider } from "./auth/custom-clerk-provider";
import { ReduxProvider } from "./store/redux-provider";

createRoot(document.getElementById("root")!).render(
  <CustomClerkProvider>
    <ReduxProvider>
      <App />
    </ReduxProvider>
  </CustomClerkProvider>
);
