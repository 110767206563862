import { ClerkProvider } from "@clerk/clerk-react";

//@ts-ignore (env throws linting error for some reason)
const LOCAL_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
// const PLATFORMSH_KEY = process.env.PLATFORM_VITE_CLERK_PUBLISHABLE_KEY;
// const config = platform.config();
// const PLATFORMSH_KEY = config.variable("VITE_CLERK_PUBLISHABLE_KEY");

// const PLATFORM_KEY_PREFIX = config.PLATFORM_VITE_CLERK_PUBLISHABLE_KEY;

// const PUBLISHABLE_KEY = LOCAL_KEY || PLATFORMSH_KEY;

//@ts-ignore
const mode = import.meta.env.MODE;
let hardCodedClerkKey = "";

switch (mode) {
  case "dev":
    hardCodedClerkKey =
      "pk_test_dG9wLWpheWJpcmQtMjUuY2xlcmsuYWNjb3VudHMuZGV2JA";
    break;
  // case "test":
  //   hardCodedClerkKey =
  //     "pk_live_Y2xlcmsudGVzdC10NmRuYmFpLWQzYWRxMmI3ZndnMzYuZXUtNS5wbGF0Zm9ybXNoLnNpdGUk";
  //   break;
  case "test":
    hardCodedClerkKey = "pk_live_Y2xlcmsubWFjaGluZXZlcnRpY2FsLmNvbSQ";
    break;
  default:
    break;
}

const PUBLISHABLE_KEY = LOCAL_KEY || hardCodedClerkKey;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

export const CustomClerkProvider = (props: { children?: React.ReactNode }) => {
  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      {props.children}
    </ClerkProvider>
  );
};
