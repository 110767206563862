export enum EEnrollmentStatus {
  INITIATED = "INITIATED",
  OFFER_RECEIVED = "OFFER_RECEIVED",
  OFFER_SENT_TO_CLIENT = "OFFER_SENT_TO_CLIENT",
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  AGREEMENT_SIGNED = "AGREEMENT_SIGNED",
  CLOSED = "CLOSED",
}

export enum EEnrollmentMyEnrollmentsFilters {
  INITIATED = "INITIATED",
  OFFERED = "OFFERED",
  AGREED = "AGREED",
  CLOSED = "CLOSED",
}
