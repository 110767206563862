import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { useEffect } from "react";

export const HomeEquipmentProvider = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Home",
        link: "/",
      },
    ]);
  }, []);

  return <div>{/*  */}</div>;
};
