import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TBreadcrumbItem = {
  label: React.ReactNode;
  link: string;
};

export type TBreadcrumbsState = {
  items: TBreadcrumbItem[];
};

const initialState: TBreadcrumbsState = {
  items: [],
};

export const breadcrumbsSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    setBreadcrumbsItems: (
      state,
      action: PayloadAction<TBreadcrumbsState["items"]>
    ) => {
      state.items = action.payload;
    },
  },
});

export const { setBreadcrumbsItems } = breadcrumbsSlice.actions;
export default breadcrumbsSlice.reducer;
