import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type TUIState = {
  sidebarShow: boolean;
  sidebarUnfoldable: boolean;
  theme: "light" | "dark" | "auto";
};

const initialState: TUIState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  theme: "auto",
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setSidebarShow: (state, action: PayloadAction<TUIState["sidebarShow"]>) => {
      state.sidebarShow = action.payload;
    },
    setSidebarUnfoldable: (
      state,
      action: PayloadAction<TUIState["sidebarUnfoldable"]>
    ) => {
      state.sidebarUnfoldable = action.payload;
    },
    toggleTheme: (state, action: PayloadAction<TUIState["theme"]>) => {
      state.theme = action.payload;
    },
  },
});

export const { setSidebarShow, toggleTheme, setSidebarUnfoldable } =
  uiSlice.actions;
export default uiSlice.reducer;
