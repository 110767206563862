import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import CustomSelect from "@/components/forms/custom/custom-select/custom-select";
import CustomTextarea from "@/components/forms/custom/custom-textarea/custom-textarea";
import { RequiredAsterist } from "@/components/forms/custom/required-asterisk/required-asterisk";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { TGroupNameQuery, TListingCreateForm } from "../listing-create";
import { FormikErrors } from "formik";

type TListingCreateBasicInformationProps = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: TListingCreateForm;
  errors: FormikErrors<TListingCreateForm>;
  groupId: number;
  dataGroupName?: TGroupNameQuery;
  isLoadingGroupName: boolean;
  hasSubmittedDraft: boolean;
  hasSubmittedPublish: boolean;
};

export const ListingCreateBasicInformation = ({
  handleChange,
  values,
  isLoadingGroupName,
  groupId,
  dataGroupName,
  errors,
  hasSubmittedDraft,
  hasSubmittedPublish,
}: TListingCreateBasicInformationProps) => {
  const hasSubmittedEither = hasSubmittedDraft || hasSubmittedPublish;

  return (
    <>
      <h5 className="px-2 pt-4 pb-0">Basic Information</h5>
      <div className="p-3 border border-gray-200 rounded-lg">
        {isLoadingGroupName ? (
          <>
            <label className="form-label">Group</label>
            <DefaultSkeleton
              className="h-[38px] mb-4 w-full max-w-full"
              classNameInner="h-10 mt-0 w-full rounded-lg"
            />
          </>
        ) : (
          <CustomInput
            className="mb-4"
            id="title"
            label={<>Group</>}
            type="text"
            value={`#${groupId}: ${dataGroupName?.name || ""}`}
            disabled
            divWrap
          />
        )}

        <CustomInput
          className="mb-4"
          id="title"
          label={
            <>
              Title
              <RequiredAsterist />
            </>
          }
          type="text"
          value={values.title}
          onChange={handleChange}
          valid={!errors.title && hasSubmittedEither}
          invalid={!!errors.title && hasSubmittedEither}
          feedbackInvalid={errors.title}
          divWrap
        />
        <div className="mb-4">
          <CustomSelect
            id="category"
            label={
              <>
                Category
                <RequiredAsterist />
              </>
            }
            placeholder="placeholder"
            presetOptions="categories"
            emptyOptionLabel="Select category"
            defaultValue={undefined}
            value={values.category}
            valid={!errors.category && hasSubmittedEither}
            invalid={!!errors.category && hasSubmittedEither}
            feedbackInvalid={errors.category}
            onChange={handleChange}
          />
        </div>
        <CustomTextarea
          id="description"
          label={
            <>
              Description
              <RequiredAsterist />
            </>
          }
          value={values.description}
          onChange={handleChange}
          valid={
            !!errors.description &&
            (hasSubmittedPublish || (hasSubmittedDraft && !!values.description))
          }
          invalid={
            !!errors.description &&
            (hasSubmittedPublish || (hasSubmittedDraft && !!values.description))
          }
          feedbackInvalid={errors.description}
          divWrap
        />
      </div>
    </>
  );
};
