import * as yup from "yup";
import { TListingCreateForm } from "./listing-create";
import {
  MAX_ATTACHMENTS,
  MAX_PARTICIPANTS,
  MIN_PARTICIPANTS,
} from "@/constants/form-constatns";

export const listingCreateSchema = yup.object<TListingCreateForm>().shape({
  title: yup.string().required("Required").max(100, "max 255 characters"),
  category: yup.string().required("Required"),
  description: yup
    .string()
    .required("Required (Publish)")
    .max(1000, "Max 1000 characters"),
  specification: yup
    .string()
    .required("Required (Publish)")
    .max(1000, "Max 1000 characters"),
  optional_specification: yup
    .string()
    .optional()
    .max(1000, "Max 1000 characters"),
  country: yup
    .string()
    .required("Required (Publish)")
    .max(100, "Max 100 characters"),
  region: yup.string().optional().max(100, "Max 100 characters"),
  images: yup
    .array()
    .max(MAX_ATTACHMENTS, `Max ${MAX_ATTACHMENTS} attachments`),
  participants: yup
    .number()
    .min(
      MIN_PARTICIPANTS,
      `Minimum of ${MIN_PARTICIPANTS} participant${MIN_PARTICIPANTS > 1 ? "s" : ""}`
    )
    .max(MAX_PARTICIPANTS, `Max ${MAX_PARTICIPANTS} participants`),
  deadline: yup
    .date()
    .required("Required")
    .min(
      new Date(new Date().setDate(new Date().getDate() + 1))
        .toISOString()
        .split("T")[0],
      "Must be later than today"
    ),
  budget: yup.number().optional().min(0, "Minimum of 0"),
});

export const listingCreateDraftSchema = yup.object<TListingCreateForm>().shape({
  title: yup.string().required("Required").max(100, "max 255 characters"),
  category: yup.string(),
  description: yup.string().max(1000, "max 1000 characters"),
  specification: yup.string().max(1000, "max 1000 characters"),
  optional_specification: yup.string().max(1000, "Max 1000 characters"),
  country: yup.string().max(100, "Max 100 characters"),
  region: yup.string().max(100, "Max 100 characters"),
  images: yup
    .array()
    .max(MAX_ATTACHMENTS, `Maximum of ${MAX_ATTACHMENTS} attachments allowed`),
  participants: yup.number().min(MIN_PARTICIPANTS).max(MAX_PARTICIPANTS),
  deadline: yup
    .date()
    .optional()
    .nullable()
    .min(
      new Date(new Date().setDate(new Date().getDate() + 1))
        .toISOString()
        .split("T")[0],
      "Must be later than today"
    ),
  budget: yup.number().optional().min(0, "Minimum of 0"),
});
