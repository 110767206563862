import { EEnrollmentStatus } from "@/enums/enrollment-enums";
import { Fragment, PropsWithChildren } from "react";

type TStepInProcessProps = {
  status?: EEnrollmentStatus;
};

type TBallProps = {
  numberOfStep: number;
  isActive: boolean;
  isCurrent: boolean;
} & PropsWithChildren;

type TLinePrpps = {
  isActive: boolean;
};

const Circle = (props: TBallProps) => {
  const { isActive, children, numberOfStep, isCurrent } = props;
  return (
    <div className="flex flex-col items-center">
      <div
        className={`rounded-full font-bold text-white w-10 h-10 ${isActive ? "bg-green-500" : "bg-slate-400"} ${isCurrent ? "scale-110" : ""} flex items-center justify-center`}
      >
        {numberOfStep}
      </div>
      <div
        className={` ${isActive ? "text-green-500" : "text-slate-400"} ${isCurrent ? "border-b-2 font-bold border-green-500" : ""}`}
      >
        {children}
      </div>
    </div>
  );
};

const Line = (props: TLinePrpps) => {
  const { isActive } = props;
  return (
    <div
      className={`border-t flex-1 h-1 mt-[-12px] ${isActive ? "border-green-500" : ""}`}
    >
      {" "}
    </div>
  );
};

export const StepInProcessEnrollment = (props: TStepInProcessProps) => {
  const processes = [
    {
      status: EEnrollmentStatus.INITIATED,
      title: "Initiated",
    },
    {
      status: EEnrollmentStatus.OFFER_RECEIVED,
      title: "Offer Received",
    },
    {
      status: EEnrollmentStatus.OFFER_SENT_TO_CLIENT,
      title: "Offer Sent to Client",
    },
    {
      status: EEnrollmentStatus.OFFER_ACCEPTED,
      title: "Offer Accepted",
    },
    {
      status: EEnrollmentStatus.AGREEMENT_SIGNED,
      title: "Agreement Signed",
    },
    {
      status: EEnrollmentStatus.CLOSED,
      title: "Closed",
    },
  ];

  return (
    <div
      className={`flex items-center justify-between w-full gap-2 px-6 pt-4 pb-3 border border-gray-200 rounded-lg transition-all `}
    >
      {processes.map((process, index) => {
        const isActive =
          index <= processes.findIndex((p) => p.status === props.status);
        const isCurrent =
          index === processes.findIndex((p) => p.status === props.status);
        if (index === 0) {
          return (
            <Circle
              key={index}
              numberOfStep={index + 1}
              isActive={isActive}
              isCurrent={isCurrent}
            >
              {process.title}
            </Circle>
          );
        } else {
          return (
            <Fragment key={index}>
              <Line isActive={isActive} />
              <Circle
                numberOfStep={index + 1}
                isActive={isActive}
                isCurrent={isCurrent}
              >
                {process.title}
              </Circle>
            </Fragment>
          );
        }
      })}
    </div>
  );
};
