import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { authedFetch } from "@/utils/authed-fetch";
import { dateToYearMonthDay } from "@/utils/date-fns";
import { useAuth } from "@clerk/clerk-react";
import {
  cilBan,
  cilCalendar,
  cilCheckCircle,
  cilPlus,
  cilSearch,
  cilUser,
  cilXCircle,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CInputGroup, CInputGroupText, CTooltip } from "@coreui/react";
import { useQuery } from "@tanstack/react-query";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ListingsTable } from "../../listings/components/listings-table";
import { EListingCategory, EListingStatus } from "@/enums/listing-enums";
import { CustomStatusBadge } from "@/components/forms/custom/custom-badge/custom-status-badge";
import { CustomPagination } from "@/components/forms/custom/custom-pagination/custom-pagination";
import { useChangeListingGroupNameMutation } from "../../hooks/use-change-listing-group-name-mutation";
import { useAppDispatch } from "@/store/hooks";
import { setModalConfirmation } from "@/store/modal/slice";
import { useDeleteListingMutation } from "../../hooks/use-delete-listing-mutation";
import { TableShadowWrapper } from "@/components/forms/custom/custom-table/table-shadow-wrapper";

type TGroupQuery = {
  id: number;
  name: string;
  created_at: string;
  created_by: string;
};

type TListingsQuery = {
  listings: {
    id: number;
    group_id: number;
    title: string;
    created_by: string;
    created_at: string;
    status: EListingStatus;
    category: EListingCategory;
    isHidden: 0 | 1;
  }[];
  count: number;
};

type TSpreadableParams = {
  p?: string;
  ps?: string;
};

export const GroupView = () => {
  const { getToken } = useAuth();
  const { groupId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pageRef = useRef(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isEditingName, setIsEditingName] = useState(false);
  const [editingNameValue, setEditingNameValue] = useState("");
  const [isInvalidNameValue, setIsInvalidNameValue] = useState(false);

  const p = parseInt(searchParams.get("p") || "1");
  const ps = parseInt(searchParams.get("ps") || "0");

  const spreadableParams: TSpreadableParams = {};
  if (p && p <= 1) spreadableParams.p = p.toString();
  if (ps) spreadableParams.ps = ps.toString();

  const {
    data: groupData,
    isLoading: groupIsLoading,
    refetch: refetchGroup,
  } = useQuery<TGroupQuery>({
    queryKey: ["group", groupId],
    queryFn: async ({ queryKey }) => {
      const [_, groupId] = queryKey;

      const groupRes = await authedFetch({
        endpoint: `api/office/listing-group/preview/${groupId}`,
        token: await getToken(),
      }).then((res) => res.json());
      return groupRes;
    },
  });

  const {
    data: listingsData,
    isLoading: listingsIsLoading,
    refetch: listingsRefetch,
  } = useQuery<TListingsQuery>({
    queryKey: ["listings", groupId, p, ps],
    queryFn: async ({ queryKey }) => {
      const [_, groupId, p, ps] = queryKey;

      const gParam = groupId ? `g=${groupId}` : "";
      const pParam = p ? `p=${p}` : "";
      const psParam = ps ? `ps=${ps}` : "";

      const params =
        p || ps || groupId
          ? `?${[gParam, pParam, psParam].filter((param) => param).join("&")}`
          : "";

      const listingsRes = await authedFetch({
        endpoint: `api/office/listing${params}`,
        token: await getToken(),
      }).then((res) => res.json());
      return listingsRes;
    },
  });

  const pageCount = Math.ceil((listingsData?.count || 0) / 10);

  const deleteMutation = useDeleteListingMutation({ refetch: listingsRefetch });
  const changeNameMutation = useChangeListingGroupNameMutation({
    refetch: refetchGroup,
    onSuccess: () => setIsEditingName(false),
    onError: () => {},
  });

  const handleDelete = (id: number) => {
    deleteMutation.mutate({ id });
  };

  const dispatchDeleteModal = ({ id, title }: { id: number; title: string }) =>
    setModalConfirmation({
      isOpen: true,
      title: "Delete Listing",
      message: "Are you sure you want to delete this listing?",
      messageSecondary: `#${id}: ${title}`,
      buttons: {
        cancel: {
          label: "Cancel",
          color: "dark",
          variant: "outline",
        },
        confirm: {
          label: "Delete",
          color: "danger",
          onClick: () => handleDelete(id),
        },
      },
    });

  const handlerChangePage = (page: number) => {
    if (page) {
      setSearchParams({ ...spreadableParams, p: page.toString() });
    } else {
      delete spreadableParams.p;
      setSearchParams({ ...spreadableParams });
    }
  };

  const handlerClickEdit = () => {
    setIsEditingName((prev) => !prev);
    setEditingNameValue(groupData?.name || "");
  };

  const handlerClickNameSubmit = () => {
    if (editingNameValue.length < 1) return;

    if (editingNameValue === groupData?.name) {
      setIsEditingName(false);
      return;
    }
    if (editingNameValue.length > 255) return;

    if (groupId) {
      changeNameMutation.mutate({ id: +groupId, name: editingNameValue });
    }
  };

  const handlerClickNameCancel = () => {
    setIsEditingName(false);
  };

  const handlerOnChangeName = (e: FormEvent<HTMLInputElement>) => {
    if (
      e.currentTarget.value.length > 255 ||
      e.currentTarget.value.length < 1
    ) {
      setIsInvalidNameValue(false);
    }
    setEditingNameValue(e.currentTarget.value);
  };

  const handlerClickNewListing = () => {
    navigate(`/listing-group/${groupId}/listing/create`);
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Home",
        link: "/",
      },
      {
        label: "Groups",
        link: "/listing-group",
      },
      {
        label: `Group #${groupId}`,
        link: `/listing-group/${groupId}`,
      },
    ]);
  }, []);

  return (
    <div ref={pageRef}>
      <div className="flex justify-between pb-2">
        <div>
          <h2>Group View</h2>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col">
          <label className="pb-2 text-xs text-gray-500">Group ID</label>
          {groupIsLoading ? (
            <DefaultSkeleton className="w-16" classNameInner="mt-0" />
          ) : (
            <h5 className="leading-4">#{groupData?.id}</h5>
          )}
        </div>

        <div className="flex flex-col">
          <label className="pb-2 text-xs text-gray-500">Group name</label>
          {groupIsLoading ? (
            <DefaultSkeleton
              className="w-64 h-[38px]"
              classNameInner="h-[20px]"
            />
          ) : (
            <div className="flex items-center gap-3 h-[38px]">
              {isEditingName ? (
                <CInputGroup className="max-w-[400px]">
                  <CustomInput
                    value={editingNameValue}
                    onChange={handlerOnChangeName}
                    invalid={isInvalidNameValue}
                  />
                  <CInputGroupText style={{ padding: 0 }}>
                    <CTooltip content="Submit">
                      <CButton
                        color="success"
                        style={{
                          borderRadius: 0,
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          overflow: "hidden",
                        }}
                        onClick={handlerClickNameSubmit}
                      >
                        <CIcon
                          icon={cilCheckCircle}
                          className="text-white"
                          size="lg"
                        />
                      </CButton>
                    </CTooltip>
                  </CInputGroupText>

                  <CInputGroupText style={{ padding: 0, overflow: "hidden" }}>
                    <CTooltip content="Cancel">
                      <CButton
                        color="danger"
                        style={{
                          borderRadius: 0,
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                        }}
                        onClick={handlerClickNameCancel}
                      >
                        <CIcon
                          icon={cilXCircle}
                          size="lg"
                          className="text-white"
                        />
                      </CButton>
                    </CTooltip>
                  </CInputGroupText>
                </CInputGroup>
              ) : (
                <>
                  <h3 className="leading-5">{groupData?.name}</h3>
                  <CButton
                    size="sm"
                    variant="outline"
                    color="success"
                    className="py-0 hover:text-white"
                    type="button"
                    onClick={handlerClickEdit}
                  >
                    Edit
                  </CButton>
                </>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <label className="pb-2 text-xs text-gray-500">Created by</label>
          <h5 className="flex items-center gap-2 leading-4">
            <CIcon icon={cilUser} />
            {groupIsLoading ? (
              <DefaultSkeleton
                className="w-28 h-[16px]"
                classNameInner="mt-0"
              />
            ) : (
              <span>{groupData?.created_by || ""}</span>
            )}
          </h5>
        </div>
        <div className="flex flex-col">
          <label className="pb-2 text-xs text-gray-500">Created at</label>
          <h5 className="flex items-center gap-2 leading-4">
            <CIcon icon={cilCalendar} />
            {groupIsLoading ? (
              <DefaultSkeleton
                className="w-28 h-[16px]"
                classNameInner="mt-0"
              />
            ) : (
              <span>{dateToYearMonthDay(groupData?.created_at || "")}</span>
            )}
          </h5>
        </div>

        <div className="flex items-end justify-between pb-2">
          <div>
            <h2>Group Listings</h2>
          </div>

          <div>
            <CButton
              color="info"
              type="button"
              onClick={handlerClickNewListing}
            >
              <span className="flex items-center gap-1 text-white">
                <CIcon icon={cilPlus} />

                <span>Create New Listing</span>
              </span>
            </CButton>
          </div>
        </div>

        {/* <div className="flex flex-col items-center"> */}
        <TableShadowWrapper>
          <ListingsTable
            tbody={
              listingsData?.listings?.map?.((listing) => ({
                id: listing?.id,
                group_id: listing?.group_id,
                title: listing?.title,
                created_by: listing?.created_by,
                status: <CustomStatusBadge status={listing?.status} />,
                category: listing?.category,
                created_at: dateToYearMonthDay(listing?.created_at),
                isHidden: listing?.isHidden,
                hidden: listing?.isHidden,

                actions: (
                  <div className="flex w-auto gap-1">
                    <CTooltip content="View" container={pageRef.current}>
                      <CButton
                        color="info"
                        onClick={() => {
                          navigate(
                            `/listing-group/${groupId}/listing/${listing.id}`
                          );
                        }}
                      >
                        <span className="text-white">
                          <CIcon icon={cilSearch} />
                        </span>
                      </CButton>
                    </CTooltip>

                    <CTooltip content="Remove" container={pageRef.current}>
                      <CButton
                        color="danger"
                        onClick={() => {
                          dispatch(
                            dispatchDeleteModal({
                              id: listing.id,
                              title: listing.title,
                            })
                          );
                        }}
                      >
                        <span className="text-white">
                          <CIcon icon={cilBan} />
                        </span>
                      </CButton>
                    </CTooltip>
                  </div>
                ),
              })) || []
            }
            isLoading={listingsIsLoading}
            count={listingsData?.count || 0}
          />
        </TableShadowWrapper>
        <div className="flex justify-center">
          <CustomPagination
            pageCount={pageCount}
            currentPage={p}
            onPageChange={handlerChangePage}
          />
        </div>
      </div>
    </div>
  );
};
