import { CustomButton } from "@/components/forms/custom/custom-button/custom-button";
import { EEnrollmentStatus } from "@/enums/enrollment-enums";
import { cilCheckCircle } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

type TPageDiscoverShowButtonsProps = {
  goBack: () => void;
  isEnrollmentInitiated: boolean;
  handleDelete: () => void;
  handleParticipate: () => void;
  enrollmentStatus?: EEnrollmentStatus;
};

export const PageDiscoverShowButtons = ({
  goBack,

  isEnrollmentInitiated,
  handleDelete,
  handleParticipate,
  enrollmentStatus,
}: TPageDiscoverShowButtonsProps) => {
  return (
    <>
      <CustomButton
        color="secondary"
        variant="outline"
        type="button"
        onClick={goBack}
      >
        Go back
      </CustomButton>
      {isEnrollmentInitiated ? (
        <CustomButton
          color="danger"
          type="button"
          onClick={handleDelete}
          className="text-white"
        >
          Decline
        </CustomButton>
      ) : null}

      <CustomButton
        color="info"
        className="text-white"
        type="button"
        onClick={handleParticipate}
        disabled={!!enrollmentStatus}
      >
        {!!enrollmentStatus ? (
          <span className="flex items-center gap-1">
            <CIcon icon={cilCheckCircle} />
            <span>Already Participating</span>
          </span>
        ) : (
          "I Want To Participate"
        )}
      </CustomButton>
    </>
  );
};
