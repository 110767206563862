import { EEnrollmentStatus } from "@/enums/enrollment-enums";
import { CBadge } from "@coreui/react";

type TCategoryBadgeProps = {
  status: EEnrollmentStatus;
  isGray?: boolean;
};

export const EnrollmentStatusBadge = (props: TCategoryBadgeProps) => {
  const { status, isGray } = props;

  let text: string = status;
  let bgColor = "";

  switch (status) {
    case EEnrollmentStatus.INITIATED:
      text = "Initiated";
      bgColor = isGray ? "bg-secondary" : "bg-indigo-600";
      break;
    case EEnrollmentStatus.OFFER_RECEIVED:
      text = "Offer Received";
      bgColor = isGray ? "bg-secondary" : "bg-blue-500";
      break;
    case EEnrollmentStatus.OFFER_SENT_TO_CLIENT:
      text = "Offer Sent to Client";
      bgColor = isGray ? "bg-secondary" : "bg-blue-500";
      break;
    case EEnrollmentStatus.OFFER_ACCEPTED:
      text = "Offer Accepted";
      bgColor = isGray ? "bg-secondary" : "bg-blue-500";
      break;
    case EEnrollmentStatus.AGREEMENT_SIGNED:
      text = "Agreement Signed";
      bgColor = isGray ? "bg-secondary" : "bg-yellow-500";
      break;
    case EEnrollmentStatus.CLOSED:
      text = "Closed";
      bgColor = isGray ? "bg-secondary" : "bg-red-500";
      break;
    default:
      break;
  }

  const className = `${bgColor}`;

  return <CBadge className={className}>{text}</CBadge>;
};
