import { EListingStatus } from "@/enums/listing-enums";
import { TListingEditForm } from "../view/listing-view-edit";
import { authedFetch } from "@/utils/authed-fetch";

type TEditListingFetcherProps = {
  formData: TListingEditForm;
  listingId: number;
  groupId: number;
  status: EListingStatus.OPEN | EListingStatus.DRAFT;
  token: string | null;
  files: File[];
  filesToDelete: string[];
};

export const editListingFetcher = async (props: TEditListingFetcherProps) => {
  const { formData, listingId, groupId, status, token, files, filesToDelete } =
    props;

  const listingRes = await authedFetch({
    endpoint: `api/office/listing/edit/${listingId}`,
    method: "PUT",
    body: {
      id: listingId,
      title: formData.title,
      description: formData.description,
      category: formData.category,
      status: status,
      specification: formData.specification,
      optional_specification: formData.optional_specification,
      location_id: formData.locationId,
      setup_id: formData.setupId,
      is_hidden: formData.is_hidden,
      budget: formData.budget,
      setup: {
        id: formData.setupId,
        participants: formData.participants,
        deadline: formData.deadline,
      },
      location: {
        id: formData.locationId,
        country: formData.country,
        region: formData.region,
      },
    },
    token: token,
  }).then(async (res) => {
    if (res.status >= 400) {
      const data = await res.json();
      throw new Error(data.message);
    }
    return res.json();
  });

  if (!!files?.length || !!filesToDelete?.length) {
    const attachmentFormData = new FormData();

    if (files?.length) {
      files.forEach((file) => {
        attachmentFormData.append(`file`, file);
      });
    }

    if (filesToDelete?.length) {
      filesToDelete.forEach((fileUrl) => {
        attachmentFormData.append(`files_to_delete`, fileUrl);
      });
    }

    // @ts-ignore
    const endpoint = import.meta.env.VITE_API_ENDPOINT;
    const attachmentsRes = await fetch(
      `${endpoint}/api/office/listing/attachment/${groupId}/${listingRes?.repoRes?.id}/edit`,
      {
        method: "POST",
        body: attachmentFormData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(async (res) => {
      if (res.status >= 400) {
        const resData = await res.json();
        throw new Error(resData.message);
      }
    });
    return Promise.all([listingRes, attachmentsRes]);
  } else {
    return listingRes;
  }
};
