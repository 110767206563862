import { listingCategoryOptions } from "./listing-category-options";

export type TOptionsSet = "categories";

export const mapOptions = (
  set: TOptionsSet
): { value: string; label: string }[] => {
  switch (set) {
    case "categories":
      return listingCategoryOptions;
    default:
      return [];
  }
};
