import { EListingCategory } from "@/enums/listing-enums";

export const listingCategoryOptions = [
  {
    label: "CNC Machinery",
    value: EListingCategory.CNC,
  },
  {
    label: "Woodworking Machinery",
    value: EListingCategory.WOOD_MACHINERY,
  },
  {
    label: "Wood Scanners",
    value: EListingCategory.WOOD_SCANNERS,
  },
  {
    label: "Furniture Machinery",
    value: EListingCategory.FURNITURE_MACHINERY,
  },
  {
    label: "Metalworking Machinery",
    value: EListingCategory.METAL_MACHINERY,
  },
  {
    label: "Software",
    value: EListingCategory.SOFTWARE,
  },
  {
    label: "Other equipment",
    value: EListingCategory.OTHER_EQUIPMENT,
  },
];
