import { CustomDatePicker } from "@/components/forms/custom/custom-datepicker/custom-datepicker";
import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import CustomTextarea from "@/components/forms/custom/custom-textarea/custom-textarea";
import { RequiredAsterist } from "@/components/forms/custom/required-asterisk/required-asterisk";
import { CFormSwitch, CInputGroup, CInputGroupText } from "@coreui/react";
import { TListingCreateForm } from "../listing-create";
import { FormikErrors } from "formik";

type TListingCreateSpecificationsProps = {
  values: TListingCreateForm;
  errors: FormikErrors<TListingCreateForm>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  hasSubmittedDraft: boolean;
  hasSubmittedPublish: boolean;
  isRestrictedBudget: boolean;
  setIsRestrictedBudget: (isRestrictedBudget: boolean) => void;
};

export const ListingCreateSpecifications = ({
  values,
  errors,
  handleChange,
  isRestrictedBudget,
  setIsRestrictedBudget,
  hasSubmittedDraft,
  hasSubmittedPublish,
}: TListingCreateSpecificationsProps) => {
  const hasSubmittedEither = hasSubmittedDraft || hasSubmittedPublish;

  return (
    <>
      <h5 className="px-2 pt-4 pb-0">Specifications</h5>
      <div className="p-3 border border-gray-200 rounded-lg">
        <div className="mb-4">
          <CustomTextarea
            id="specification"
            label={
              <>
                Required Specifications
                <RequiredAsterist />
              </>
            }
            value={values.specification}
            onChange={handleChange}
            valid={
              !errors.specification &&
              (hasSubmittedPublish ||
                (hasSubmittedDraft && !!values.specification))
            }
            invalid={
              !!errors.specification &&
              (hasSubmittedPublish ||
                (hasSubmittedDraft && !!values.specification))
            }
            feedbackInvalid={errors.specification}
            divWrap
            withMarkdownPreview
          />
        </div>

        <div className="mb-4">
          <CustomTextarea
            id="optional_specification"
            label={<span>Optional Specifications</span>}
            value={values.optional_specification}
            onChange={handleChange}
            valid={
              !errors.optional_specification &&
              (hasSubmittedPublish ||
                (hasSubmittedDraft && !!values.optional_specification))
            }
            invalid={!!errors.optional_specification && hasSubmittedEither}
            feedbackInvalid={errors.optional_specification}
            divWrap
            withMarkdownPreview
          />
        </div>

        <div className="mb-4">
          <CustomDatePicker
            divWrap
            divWrapProps={{
              className: "w-40",
            }}
            id="deadline"
            label={
              <>
                Deadline
                <RequiredAsterist />
              </>
            }
            onChange={(e) => {
              handleChange(e);
            }}
            value={values?.deadline?.toString?.() || ""}
            min={
              new Date(new Date().setDate(new Date().getDate() + 1))
                .toISOString()
                .split("T")[0]
            }
            valid={
              !errors.deadline &&
              (hasSubmittedPublish || (hasSubmittedDraft && !!values.deadline))
            }
            invalid={
              !!errors.deadline &&
              (hasSubmittedPublish || (hasSubmittedDraft && !!values.deadline))
            }
            feedbackInvalid={errors.deadline}
          />
        </div>

        <div>
          <label className="form-label">Restricted Budget</label>
          <div className="flex">
            <CInputGroup className="sm:flex-1">
              <CInputGroupText
                style={
                  isRestrictedBudget
                    ? {}
                    : {
                        border: "transparent",
                      }
                }
              >
                <CFormSwitch
                  size="xl"
                  onChange={() => {
                    setIsRestrictedBudget(!isRestrictedBudget);
                  }}
                  checked={isRestrictedBudget}
                />
              </CInputGroupText>
              {isRestrictedBudget && (
                <>
                  <CustomInput
                    id="budget"
                    type="number"
                    disabled={!isRestrictedBudget}
                    value={values.budget}
                    onChange={handleChange}
                  />
                  <CInputGroupText>€</CInputGroupText>
                </>
              )}
            </CInputGroup>
            <div className="sm:flex-1 sm:h-[44px]" />
          </div>
        </div>
      </div>
    </>
  );
};
