import { CustomButton } from "@/components/forms/custom/custom-button/custom-button";
import { useNavigate } from "react-router-dom";

type TGroupsAndListingsButtonsProps = {
  activeState?: "groups" | "listings";
};

export const GroupsAndListingsButtons = (
  props: TGroupsAndListingsButtonsProps
) => {
  const { activeState } = props;
  const navigate = useNavigate();

  const handleClickGroups = () => {
    if (activeState === "groups") return;
    navigate("/listing-group");
  };
  const handleClickListings = () => {
    if (activeState === "listings") return;
    navigate("/listing");
  };

  return (
    <div className="flex items-center gap-2">
      <CustomButton
        variant={activeState === "groups" ? undefined : "outline"}
        color={activeState === "groups" ? "info" : "secondary"}
        type="button"
        className={
          activeState === "groups" ? "pointer-events-none text-white" : ""
        }
        onClick={handleClickGroups}
      >
        Listing Groups
      </CustomButton>
      <CustomButton
        variant={activeState === "listings" ? undefined : "outline"}
        color={activeState === "listings" ? "info" : "secondary"}
        type="button"
        className={
          activeState === "listings" ? "pointer-events-none text-white" : ""
        }
        onClick={handleClickListings}
      >
        Listings
      </CustomButton>
    </div>
  );
};
