import { CustomMarkdown } from "./custom-markdown";

type TCustomMarkdownTextareaReadonlyProps = {
  children?: string;
};

export const CustomMarkdownTextareaReadonly = (
  props: TCustomMarkdownTextareaReadonlyProps
) => {
  return (
    <div className="px-[12px] py-[6px] border-1 rounded-md min-h-10">
      <CustomMarkdown>{props?.children ?? ""}</CustomMarkdown>
    </div>
  );
};
