type TCustomDatePickerProps = {
  id: string;
  label: React.ReactNode;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  divWrap?: boolean;
  divWrapProps?: {
    className: string;
  };
  valid?: boolean;
  invalid?: boolean;
  feedbackInvalid?: string;
} & Omit<React.HTMLProps<HTMLInputElement>, "label">;

export const CustomDatePicker = (props: TCustomDatePickerProps) => {
  const {
    id,
    label,
    onChange,
    divWrap,
    divWrapProps,
    valid,
    invalid,
    feedbackInvalid,
    ...propsRest
  } = props;

  const inputElement = (
    <>
      <input
        type="date"
        id={id}
        className={`form-control ${invalid ? "is-invalid" : ""} ${valid ? "is-valid" : ""}`}
        onChange={onChange}
        onFocus={(e) => {
          try {
            e.target.showPicker();
          } catch (e) {
            //
          }
        }}
        {...propsRest}
      />
      {invalid && feedbackInvalid ? (
        <div className="invalid-feedback">{feedbackInvalid}</div>
      ) : null}
    </>
  );

  if (divWrap) {
    return (
      <div className={divWrapProps?.className}>
        <label className="form-label">{label}</label>
        {inputElement}
      </div>
    );
  }

  return (
    <>
      <label className="form-label">{label}</label>
      <input
        type="date"
        id={id}
        className="form-control"
        onChange={onChange}
        {...propsRest}
      />
    </>
  );
};
