import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { useEffect } from "react";

export const TermsAndConditions = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        link: "/",
        label: "Home",
      },
      {
        label: "Terms and Conditions",
        link: "/terms-and-conditions",
      },
    ]);
  }, []);

  return <div>Terms and Conditions placeholder</div>;
};
