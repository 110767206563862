import { FormikErrors } from "formik";
import { TListingCreateForm } from "../listing-create";
import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import { RequiredAsterist } from "@/components/forms/custom/required-asterisk/required-asterisk";

type TListingCreateLocationProps = {
  values: TListingCreateForm;
  errors: FormikErrors<TListingCreateForm>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  hasSubmittedDraft: boolean;
  hasSubmittedPublish: boolean;
};

export const ListingCreateLocation = ({
  values,
  errors,
  handleChange,
  hasSubmittedDraft,
  hasSubmittedPublish,
}: TListingCreateLocationProps) => {
  return (
    <>
      <h5 className="px-2 pt-4 pb-0">Location</h5>
      <div className="flex gap-2 p-3 border border-gray-200 rounded-lg">
        <CustomInput
          id="country"
          type="text"
          label={
            <>
              Country
              <RequiredAsterist />
            </>
          }
          value={values.country}
          onChange={handleChange}
          valid={
            !errors.country &&
            (hasSubmittedPublish || (hasSubmittedDraft && !!values.country))
          }
          invalid={
            !!errors.country &&
            (hasSubmittedPublish || (hasSubmittedDraft && !!values.country))
          }
          feedbackInvalid={errors.country}
          divWrap
          divWrapProps={{
            className: "sm:flex-1",
          }}
        />
        <CustomInput
          id="region"
          type="text"
          label={<>Region</>}
          value={values.region}
          onChange={handleChange}
          valid={
            !errors.region &&
            (hasSubmittedPublish || (hasSubmittedDraft && !!values.region))
          }
          invalid={
            !!errors.region &&
            (hasSubmittedPublish || (hasSubmittedDraft && !!values.region))
          }
          feedbackInvalid={errors.region}
          divWrap
          divWrapProps={{
            className: "sm:flex-1",
          }}
        />
      </div>
    </>
  );
};
