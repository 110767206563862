import { EListingStatus } from "@/enums/listing-enums";
import { CBadge } from "@coreui/react";

type TCustomStatusBadgeProps = {
  status?: EListingStatus;
  size?: "sm";
};

export const CustomStatusBadge = (props: TCustomStatusBadgeProps) => {
  const { status } = props;

  switch (status) {
    case EListingStatus.OPEN:
      return <CBadge color="warning">Open</CBadge>;
    case EListingStatus.STARTED:
      return <CBadge color="primary">Started</CBadge>;
    case EListingStatus.DRAFT:
      return <CBadge color="secondary">Draft</CBadge>;
    case EListingStatus.CLOSED:
      return <CBadge color="success">Closed</CBadge>;
    default:
      return <CBadge color="secondary">{status}</CBadge>;
  }
};
