import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const TABLE_BORDER_WIDTH = "2px";
const TABLE_CELL_PADDING = "4px 8px";

type TCustomMarkdownProps = {
  children: string;
};

export const CustomMarkdown = (props: TCustomMarkdownProps) => {
  const { children } = props;

  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={{
        table: ({ children }) => (
          <table style={{ borderWidth: TABLE_BORDER_WIDTH }}>{children}</table>
        ),
        thead: ({ children }) => (
          <thead style={{ borderWidth: TABLE_BORDER_WIDTH }}>{children}</thead>
        ),
        tbody: ({ children }) => (
          <tbody style={{ borderWidth: TABLE_BORDER_WIDTH }}>{children}</tbody>
        ),
        th: ({ children }) => (
          <th
            style={{
              borderWidth: TABLE_BORDER_WIDTH,
              padding: TABLE_CELL_PADDING,
            }}
          >
            {children}
          </th>
        ),
        tr: ({ children }) => (
          <tr
            style={{
              borderWidth: TABLE_BORDER_WIDTH,
            }}
          >
            {children}
          </tr>
        ),
        td: ({ children }) => (
          <td
            style={{
              borderWidth: TABLE_BORDER_WIDTH,
              padding: TABLE_CELL_PADDING,
            }}
          >
            {children}
          </td>
        ),
        ul: ({ children }) => (
          <ul style={{ listStyleType: "revert", paddingLeft: "18px" }}>
            {children}
          </ul>
        ),
        p: ({ children }) => <p style={{ marginBottom: "4px" }}>{children}</p>,
      }}
    >
      {children}
    </Markdown>
  );
};
