import { twMerge } from "tailwind-merge";

type TDefaultSpinnerProps = {
  color:
    | "blue"
    | "grey"
    | "green"
    | "red"
    | "yellow"
    | "teal"
    | "white"
    | "black";
  className?: string;
};

export const DefaultSpinner = (props: TDefaultSpinnerProps) => {
  const { color, className } = props;

  const inner = (
    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
      Loading...
    </span>
  );

  let outer: React.ReactNode;

  switch (color) {
    case "blue":
      outer = (
        <div
          className={
            "inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
          }
          role="status"
        >
          {inner}
        </div>
      );
      break;
    case "grey":
      outer = (
        <div
          className={
            "inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-secondary motion-reduce:animate-[spin_1.5s_linear_infinite]"
          }
          role="status"
        >
          {inner}
        </div>
      );
      break;
    case "green":
      outer = (
        <div
          className={
            "inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-success motion-reduce:animate-[spin_1.5s_linear_infinite]"
          }
          role="status"
        >
          {inner}
        </div>
      );
      break;
    case "red":
      outer = (
        <div
          className={
            "inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-danger motion-reduce:animate-[spin_1.5s_linear_infinite]"
          }
          role="status"
        >
          {inner}
        </div>
      );
      break;
    case "yellow":
      outer = (
        <div
          className={
            "inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
          }
          role="status"
        >
          {inner}
        </div>
      );
      break;
    case "teal":
      outer = (
        <div
          className={
            "inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-info motion-reduce:animate-[spin_1.5s_linear_infinite]"
          }
          role="status"
        >
          {inner}
        </div>
      );
      break;
    case "white":
      outer = (
        <div
          className={
            "inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-neutral-50 motion-reduce:animate-[spin_1.5s_linear_infinite]"
          }
          role="status"
        >
          {inner}
        </div>
      );
      break;
    case "black":
      outer = (
        <div
          className={
            "inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-[#332d2d] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          }
          role="status"
        >
          {inner}
        </div>
      );
      break;
    default:
      outer = (
        <div
          className={
            "inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
          }
          role="status"
        >
          {inner}
        </div>
      );
      break;
  }

  return <div className={className}>{outer}</div>;
};
