import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import { RequiredAsterist } from "@/components/forms/custom/required-asterisk/required-asterisk";
import { TListingEditForm } from "../listing-view-edit";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";

type TListingViewCustomInputTitleProps = {
  value: TListingEditForm["title"];
  error?: string;
  hasSubmittedEither: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isEditing: boolean;
  isLoading?: boolean;
};

const title = "Title";

export const ListingViewCustomInputTitle = (
  props: TListingViewCustomInputTitleProps
) => {
  const {
    value,
    error,
    hasSubmittedEither,
    handleChange,
    isEditing,
    isLoading,
  } = props;

  if (isLoading) {
    return (
      <div className="mb-4">
        <label className="form-label">{title}</label>
        <DefaultSkeleton
          className="px-[0px] py-[0px] max-w-full"
          classNameInner="h-[30px]"
        />
      </div>
    );
  }

  if (!isEditing) {
    return (
      <div className="mb-4 ">
        <label className="form-label">{title}</label>
        <p className="px-[12px] py-[6px] border rounded min-h-[38px]">
          {value}
        </p>
      </div>
    );
  }

  return (
    <CustomInput
      className="mb-4"
      id="title"
      label={
        <>
          {title}
          <RequiredAsterist />
        </>
      }
      type="text"
      value={value}
      onChange={handleChange}
      valid={!error && hasSubmittedEither}
      invalid={!!error && hasSubmittedEither}
      feedbackInvalid={error}
      divWrap
    />
  );
};
