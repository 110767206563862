import CustomTextarea from "@/components/forms/custom/custom-textarea/custom-textarea";
import { RequiredAsterist } from "@/components/forms/custom/required-asterisk/required-asterisk";
import { TListingEditForm } from "../listing-view-edit";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";

type TListingViewCustomTextareaDescriptionProps = {
  value: TListingEditForm["description"];
  error?: string;
  hasSubmittedPublish: boolean;
  hasSubmittedDraft: boolean;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isEditing: boolean;
  isLoading?: boolean;
};

export const ListingViewCustomTextareaDescription = (
  props: TListingViewCustomTextareaDescriptionProps
) => {
  const {
    value,
    handleChange,
    error,
    hasSubmittedDraft,
    hasSubmittedPublish,
    isLoading,
    isEditing,
  } = props;

  if (isLoading) {
    return (
      <div className="">
        <label className="form-label">Description</label>
        <DefaultSkeleton
          className="px-[0px] py-[0px] max-w-full h-[62px] "
          classNameInner="h-full rounded-lg mt-0 mb-0"
        />
      </div>
    );
  }

  if (!isEditing) {
    return (
      <div className="">
        <label className="form-label">Description</label>
        <p className="px-[12px] py-[6px] border rounded min-h-[62px] mb-0">
          {value}
        </p>
      </div>
    );
  }

  return (
    <CustomTextarea
      id="description"
      label={
        <>
          Description
          <RequiredAsterist />
        </>
      }
      value={value}
      onChange={handleChange}
      valid={!!error && (hasSubmittedPublish || (hasSubmittedDraft && !!value))}
      invalid={
        !!error && (hasSubmittedPublish || (hasSubmittedDraft && !!value))
      }
      feedbackInvalid={error}
      divWrap
    />
  );
};
