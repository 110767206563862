import CustomSelect from "@/components/forms/custom/custom-select/custom-select";
import { RequiredAsterist } from "@/components/forms/custom/required-asterisk/required-asterisk";
import { TListingEditForm } from "../listing-view-edit";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { CategoryBadge } from "@/components/badges/category-badge";
import { EListingCategory } from "@/enums/listing-enums";

type TListingViewCustomSelectCategoryProps = {
  value: TListingEditForm["category"];
  error?: string;
  hasSubmittedEither: boolean;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  isLoading?: boolean;
  isEditing: boolean;
};

export const ListingViewCustomSelectCategory = (
  props: TListingViewCustomSelectCategoryProps
) => {
  const {
    value,
    error,
    hasSubmittedEither,
    handleChange,
    isLoading,
    isEditing,
  } = props;

  if (isLoading) {
    return (
      <div className="mb-4">
        <label className="form-label">Category</label>
        <DefaultSkeleton
          className="px-[0px] py-[0px] max-w-full"
          classNameInner="h-[30px]"
        />
      </div>
    );
  }

  if (!isEditing) {
    return (
      <div className="mb-4 ">
        <label className="form-label">Category</label>

        <p className="px-2 pt-[6px] pb-[4px] h-[38px] text-xl">
          <CategoryBadge category={value as EListingCategory} />
        </p>
      </div>
    );
  }

  return (
    <div>
      <CustomSelect
        id="category"
        label={
          <>
            Category
            <RequiredAsterist />
          </>
        }
        placeholder="placeholder"
        presetOptions="categories"
        emptyOptionLabel="Select category"
        defaultValue={undefined}
        value={value}
        valid={!error && hasSubmittedEither}
        invalid={!!error && hasSubmittedEither}
        feedbackInvalid={error}
        onChange={handleChange}
        divWrap
        divWrapProps={{
          className: "mb-4",
        }}
      />
    </div>
  );
};
