import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { useEffect } from "react";

export const Dashboard = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Home",
        link: "/",
      },
      {
        label: "Dashboard",
        link: "/dashboard",
      },
    ]);
  }, []);

  return (
    <div>
      <h1>Dashboard placeholder</h1>
    </div>
  );
};
