import {
  MAX_ATTACHMENT_SIZE,
  MAX_ATTACHMENTS,
  MIN_PARTICIPANTS,
} from "@/constants/form-constatns";
import { useAppDispatch } from "@/store/hooks";
import { useAuth } from "@clerk/clerk-react";
import { CTab, CTabContent, CTabList, CTabPanel, CTabs } from "@coreui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, Formik, validateYupSchema } from "formik";
import { listingCreateSchema, listingCreateDraftSchema } from "./schema";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { createListingFetcher } from "./query";
import { useNavigate, useParams } from "react-router-dom";
import { setModalConfirmation } from "@/store/modal/slice";
import CIcon from "@coreui/icons-react";
import { cilInfo, cilFile } from "@coreui/icons";
import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { authedFetch } from "@/utils/authed-fetch";
import { ListingCreateButtons } from "./components/listing-create-buttons";
import { ListingCreateBasicInformation } from "./components/listing-create-basic-information";
import { ListingCreateSpecifications } from "./components/listing-create-specifications";
import { ListingCreateLocation } from "./components/listing-create-location";
import { ListingCreateSettings } from "./components/listing-create-settings";
import { useToastsListingCreate } from "./hooks/use-toasts-listing-create";
import { ListingCreateAttachments } from "./components/listing-create-attachments";

const initialValues: TListingCreateForm = {
  title: "",
  category: "",
  description: "",
  specification: "",
  optional_specification: "",
  country: "",
  region: "",
  budget: 0,
  images: [],
  is_hidden: false,
  deadline: undefined,
  participants: MIN_PARTICIPANTS,
  group_id: 0,
};

const initialState: TListingCreateFormState = {
  files: [],
  isRestrictedBudget: false,
  hasSubmittedPublish: false,
  hasSubmittedDraft: false,
  isSubmitting: false,
};

const ListingCreate = () => {
  const dispatch = useAppDispatch();
  const {
    toastBackendError,
    toastDraftCreated,
    toastPublishCreated,
    toastMaxAttachments,
    toastMaxAttachmentSize,
  } = useToastsListingCreate();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { groupId } = useParams();
  const { setBreadcrumbs } = useBreadcrumbs();
  const pageRef = useRef(null);

  const [state, setState] = useState(initialState);
  const hasSubmittedEither =
    state.hasSubmittedPublish || state.hasSubmittedDraft;
  const setFiles = (files: TListingCreateFormState["files"]) =>
    setState((p) => ({ ...p, files }));
  const setHasSubmittedPublish = (
    hasSubmittedPublish: TListingCreateFormState["hasSubmittedPublish"]
  ) => setState((p) => ({ ...p, hasSubmittedPublish }));
  const setHasSubmittedDraft = (
    hasSubmittedDraft: TListingCreateFormState["hasSubmittedDraft"]
  ) => setState((p) => ({ ...p, hasSubmittedDraft }));
  const setIsSubmitting = (
    isSubmitting: TListingCreateFormState["isSubmitting"]
  ) => setState((p) => ({ ...p, isSubmitting }));
  const setIsRestrictedBudget = (
    isRestrictedBudget: TListingCreateFormState["isRestrictedBudget"]
  ) => setState((p) => ({ ...p, isRestrictedBudget }));

  const attachmentTabError = state.files.length > MAX_ATTACHMENTS;
  const navigateBack = () => {
    navigate(-1);
  };

  const { data: dataGroupName, isLoading: isLoadingGroupName } =
    useQuery<TGroupNameQuery>({
      queryKey: ["group-name", groupId],
      queryFn: async () => {
        return authedFetch({
          token: await getToken(),
          endpoint: `api/office/listing-group/get-name/${groupId}`,
        }).then((res) => res.json());
      },
    });

  const listingDraftMutation = useMutation({
    mutationKey: ["listing-create-draft"],
    mutationFn: async ({
      formData,
      files,
    }: {
      formData: TListingCreateForm;
      files: File[];
    }) => {
      setIsSubmitting(true);
      return createListingFetcher({
        formData: {
          ...formData,
          group_id: parseInt(groupId || "0"),
          budget: state.isRestrictedBudget ? formData.budget : 0,
        },
        files,
        status: "DRAFT",
        token: await getToken(),
      });
    },
    onError: (error) => {
      toastBackendError(error);
      setIsSubmitting(false);
    },
    onSuccess: () => {
      toastDraftCreated();
      setIsSubmitting(false);
      navigateBack();
    },
  });
  const listingMutation = useMutation({
    mutationKey: ["listing-create"],
    mutationFn: async ({
      formData,
      files,
    }: {
      formData: TListingCreateForm;
      files: File[];
    }) => {
      setIsSubmitting(true);
      return createListingFetcher({
        formData: {
          ...formData,
          group_id: parseInt(groupId || "0"),
          budget: state.isRestrictedBudget ? formData.budget : 0,
        },
        status: "OPEN",
        files,
        token: await getToken(),
      });
    },
    onError: (error) => {
      toastBackendError(error);
      setIsSubmitting(false);
    },
    onSuccess: () => {
      toastPublishCreated();
      setIsSubmitting(false);
      navigateBack();
    },
  });

  const handleOnDraft = ({ formData }: { formData: TListingCreateForm }) => {
    validateYupSchema(formData, listingCreateDraftSchema)
      .then((values) => {
        listingDraftMutation.mutate({
          formData: {
            title: values.title || "",
            category: values.category || "",
            description: values.description || "",
            specification: values.specification || "",
            optional_specification: values.optional_specification || "",
            country: values.country || "",
            region: values.region || "",
            participants: values.participants || MIN_PARTICIPANTS,
            deadline: values.deadline,
            is_hidden: values.is_hidden || false,
            budget: (values?.budget as number) > 0 ? values.budget : 0,
            group_id: parseInt(groupId || "0"),
          },
          files: state.files,
        });
      })
      .catch(() => {});
  };

  const handleAttachmentToDelete = ({
    indexOfNew,
  }: {
    indexOfNew?: number;
  }) => {
    if (indexOfNew !== undefined && indexOfNew !== null) {
      removeFileByIndex(indexOfNew);
      return;
    }
  };

  const removeFileByIndex = (index: number) => {
    const tempState = [...state.files].filter(
      (_file, _index) => _index !== index
    );
    setFiles(tempState);
  };

  const clearFiles = ({ cb }: { cb?: () => void }) => {
    setFiles([]);
    cb?.();
  };

  const handlerFilesOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const tempState: TListingCreateFormState["files"] = [...state.files];
    if (e.target.files!.length > MAX_ATTACHMENTS) {
      toastMaxAttachments();
    } else {
      for (let i = 0, len = e.target.files!.length; i < len; i++) {
        if (e.target.files![i].size < MAX_ATTACHMENT_SIZE) {
          tempState.push(e.target.files![i]);
        } else {
          clearFiles({
            cb: () => {
              toastMaxAttachmentSize();
            },
          });
          break;
        }
      }
      setFiles([...tempState]);
    }
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Home",
        link: "/",
      },
      {
        label: "Groups",
        link: "/listing-group",
      },
      {
        label: `#${groupId}`,
        link: `/listing-group/${groupId}`,
      },
      {
        label: "Listings",
        link: "/listing",
      },
      {
        label: "Create",
        link: "/listing/create",
      },
    ]);
  }, []);

  return (
    <div className="max-w-[1000px]" ref={pageRef}>
      <Formik<TListingCreateForm>
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (formsData, { validateForm }) => {
          if (state.files.length > 0) {
            validateForm(formsData);

            listingMutation.mutate({
              formData: {
                ...formsData,
              },
              files: state.files,
            });
          } else {
            dispatch(
              setModalConfirmation({
                isOpen: true,
                title: "Listing Publishing",
                message:
                  "Are you sure you want to publish without attachments?",
                buttons: {
                  cancel: {
                    color: "dark",
                    label: "Cancel",
                    variant: "outline",
                  },
                  confirm: {
                    label: "Publish",
                    color: "info",
                    onClick: () => {
                      validateForm(formsData);
                      listingMutation.mutate({
                        formData: {
                          ...formsData,
                        },
                        files: state.files,
                      });
                    },
                  },
                },
              })
            );
          }
        }}
        validateOnChange={hasSubmittedEither}
        validateOnMount={true}
        validateOnBlur={true}
        validationSchema={listingCreateSchema}
      >
        {({
          values,
          handleChange,
          errors,
          handleSubmit,
          validateForm,
          setFieldValue,
        }) => {
          // Tab error flags
          const isInfoTabErrorPublish =
            state.hasSubmittedPublish &&
            (errors.title ||
              errors.category ||
              errors.description ||
              errors.specification ||
              errors.region ||
              errors.country ||
              errors.deadline ||
              errors.participants);
          const isInfoTabErrorDraft =
            state.hasSubmittedDraft &&
            (errors.title ||
              errors.category ||
              (errors.description && values.description) ||
              (errors.specification && values.specification) ||
              (errors.region && values.region) ||
              (errors.country && values.category) ||
              (errors.deadline && values.deadline) ||
              (errors.participants && values.participants));
          const isInfoTabError = isInfoTabErrorPublish || isInfoTabErrorDraft;

          const handleOnClickPublish = () => {
            if (state.isSubmitting) return;
            if (state.files.length > MAX_ATTACHMENTS) {
              toastMaxAttachments();
              return;
            }
            setHasSubmittedPublish(true);
            handleSubmit();
          };

          const handleOnClickDraft = () => {
            if (state.isSubmitting) return;
            if (state.files.length > MAX_ATTACHMENTS) {
              toastMaxAttachments();
              return;
            }
            setHasSubmittedDraft(true);
            validateForm(values);
            handleOnDraft({ formData: values });
          };

          const handleOnClickBack = () => {
            if (state.isSubmitting) return;
            navigateBack();
          };

          return (
            <>
              <CTabs activeItemKey={"info"}>
                <CTabList variant="tabs">
                  <CTab itemKey="info">
                    <span className={`${isInfoTabError ? "text-red-500" : ""}`}>
                      <CIcon icon={cilInfo} /> Info
                    </span>
                  </CTab>
                  <CTab itemKey="attachments">
                    <span
                      className={`${attachmentTabError ? "text-red-500" : ""}`}
                    >
                      <CIcon icon={cilFile} /> Attachments
                    </span>
                  </CTab>
                </CTabList>
                <CTabContent>
                  <CTabPanel itemKey="info" id="info-tab-panel">
                    <Form>
                      <div className="flex flex-col pt-2">
                        <ListingCreateBasicInformation
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          hasSubmittedDraft={state.hasSubmittedDraft}
                          hasSubmittedPublish={state.hasSubmittedPublish}
                          groupId={+(groupId || 0)}
                          dataGroupName={dataGroupName}
                          isLoadingGroupName={isLoadingGroupName}
                        />

                        <ListingCreateSpecifications
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          hasSubmittedDraft={state.hasSubmittedDraft}
                          hasSubmittedPublish={state.hasSubmittedPublish}
                          isRestrictedBudget={state.isRestrictedBudget}
                          setIsRestrictedBudget={setIsRestrictedBudget}
                        />

                        <ListingCreateLocation
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          hasSubmittedDraft={state.hasSubmittedDraft}
                          hasSubmittedPublish={state.hasSubmittedPublish}
                        />

                        <ListingCreateSettings
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          hasSubmittedDraft={state.hasSubmittedDraft}
                          hasSubmittedPublish={state.hasSubmittedPublish}
                        />
                      </div>
                    </Form>
                  </CTabPanel>
                  <CTabPanel itemKey="attachments" id="attachments-tab-panel">
                    <div className="flex flex-col gap-3 pt-6">
                      <ListingCreateAttachments
                        files={state.files}
                        handleAttachmentToDelete={handleAttachmentToDelete}
                        handlerFilesOnChange={handlerFilesOnChange}
                        pageRef={pageRef}
                      />
                    </div>
                  </CTabPanel>
                </CTabContent>
              </CTabs>

              <ListingCreateButtons
                disabled={state.isSubmitting}
                onClickCancel={handleOnClickBack}
                onClickDraft={handleOnClickDraft}
                onClickPublish={handleOnClickPublish}
              />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

type TListingCreateFormState = {
  files: File[];
  hasSubmittedPublish: boolean;
  hasSubmittedDraft: boolean;
  isSubmitting: boolean;
  isRestrictedBudget: boolean;
};

export type TListingCreateForm = {
  title: string;
  category: string;
  description: string;
  specification?: string;
  optional_specification?: string;
  country?: string;
  region?: string;
  budget?: number;
  images?: string[];
  participants?: number;
  deadline?: Date;
  is_hidden: boolean;
  group_id: number;
};

export type TGroupNameQuery = {
  name: string;
};

export default ListingCreate;
