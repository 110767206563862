import {
  CFormSelect,
  CFormSelectProps,
} from "@coreui/react/dist/esm/components/form/CFormSelect";
import { TOptionsSet, mapOptions } from "./options/map-options";
import { useMemo } from "react";
import CIcon from "@coreui/icons-react";
import { cilX } from "@coreui/icons";

type TCustomSelectProps = {
  presetOptions?: TOptionsSet;
  emptyOptionLabel?: string;
  divWrap?: boolean;
  divWrapProps?: React.ComponentProps<"div">;
  clearable?: boolean;
} & CFormSelectProps;

const CustomSelect = (props: TCustomSelectProps) => {
  const {
    presetOptions,
    emptyOptionLabel,
    divWrap,
    divWrapProps,
    clearable,
    ...propsRest
  } = props;
  let options: ReturnType<typeof mapOptions> = [];
  if (presetOptions)
    options = useMemo(() => {
      if (emptyOptionLabel) {
        return [
          { value: "", label: emptyOptionLabel },
          ...mapOptions(presetOptions),
        ];
      }
      return [...mapOptions(presetOptions)];
    }, [props]);

  const select = (
    <CFormSelect {...propsRest} {...(presetOptions ? { options } : {})} />
  );

  if (!divWrap) return select;

  return (
    <div className={`relative ${divWrapProps?.className}`}>
      {select}
      {clearable && propsRest.value && (
        <CIcon
          icon={cilX}
          className="absolute right-3 top-3 cursor-pointer"
          onClick={() => {
            if (propsRest.onChange) {
              propsRest.onChange({
                target: { value: "" },
              } as React.ChangeEvent<HTMLSelectElement>);
            }
          }}
        />
      )}
    </div>
  );
};

export default CustomSelect;
