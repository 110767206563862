import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { useEffect } from "react";

export const ProcessOverview = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        link: "/",
        label: "Home",
      },
      {
        label: "Process Overview",
        link: "/process-overview",
      },
    ]);
  }, []);

  return <div>Process Overview placeholder</div>;
};
