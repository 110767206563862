import { EUserAccountType } from "@/enums/user-metadata";
import { useAuth, useUser } from "@clerk/clerk-react";

type TPageAuthWrapperProps = {
  defaultElement?: JSX.Element;
  officeUserElement?: JSX.Element;
  equipmentProviderElement?: JSX.Element;
};

export const PageAuthSwitch = (props: TPageAuthWrapperProps) => {
  const { defaultElement, equipmentProviderElement, officeUserElement } = props;
  const {} = useAuth();
  const { user, isLoaded, isSignedIn } = useUser();

  if (
    isLoaded &&
    isSignedIn &&
    user.publicMetadata?.accountType === EUserAccountType.OFFICE_USER &&
    officeUserElement
  ) {
    return officeUserElement;
  }
  if (
    isLoaded &&
    isSignedIn &&
    user.publicMetadata?.accountType === EUserAccountType.EQUIPMENT_PROVIDER &&
    equipmentProviderElement
  ) {
    return equipmentProviderElement;
  }
  if (isLoaded && !isSignedIn && !user) {
    return defaultElement;
  }

  return <></>;
};
