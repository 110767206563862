import { CustomDatePicker } from "@/components/forms/custom/custom-datepicker/custom-datepicker";
import { TListingEditForm } from "../listing-view-edit";
import { RequiredAsterist } from "@/components/forms/custom/required-asterisk/required-asterisk";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { dateToYearMonthDay } from "@/utils/date-fns";
import CIcon from "@coreui/icons-react";
import { cilCalendar } from "@coreui/icons";

type TListingViewDeadlineProps = {
  value: TListingEditForm["deadline"];
  error?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasSubmittedPublish: boolean;
  hasSubmittedDraft: boolean;
  isEditing: boolean;
  isLoading?: boolean;
};

const labelTitle = "Deadline";

export const ListingViewDeadline = (props: TListingViewDeadlineProps) => {
  const {
    value,
    error,
    handleChange,
    hasSubmittedPublish,
    hasSubmittedDraft,
    isEditing,
    isLoading,
  } = props;

  if (isLoading) {
    return (
      <div className="mb-4">
        <label className="form-label">{labelTitle}</label>
        <DefaultSkeleton
          className="px-[0px] py-[0px] w-[140px]"
          classNameInner="h-[30px]"
        />
      </div>
    );
  }

  if (!isEditing) {
    return (
      <div className="mb-4 ">
        <label className="form-label">{labelTitle}</label>
        <p className="px-1 py-[7px] flex items-center gap-2 font-bold">
          <CIcon icon={cilCalendar} />
          {dateToYearMonthDay(value || "")}
        </p>
      </div>
    );
  }

  return (
    <div className="mb-4">
      <CustomDatePicker
        divWrap
        divWrapProps={{
          className: "w-40",
        }}
        id="deadline"
        label={
          <>
            {labelTitle}
            <RequiredAsterist />
          </>
        }
        onChange={handleChange}
        value={value?.toString?.() || ""}
        min={
          new Date(new Date().setDate(new Date().getDate() + 1))
            .toISOString()
            .split("T")[0]
        }
        valid={
          !error && (hasSubmittedPublish || (hasSubmittedDraft && !!value))
        }
        invalid={
          !!error && (hasSubmittedPublish || (hasSubmittedDraft && !!value))
        }
        feedbackInvalid={error}
      />
    </div>
  );
};
