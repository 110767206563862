import { CFormRange, CFormSwitch } from "@coreui/react";
import { TListingEditForm } from "../listing-view-edit";
import { MAX_PARTICIPANTS, MIN_PARTICIPANTS } from "@/constants/form-constatns";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";

type TListingViewSettingsProps = {
  valueHidden: TListingEditForm["is_hidden"];
  valueParticipants: TListingEditForm["participants"];
  valueEnrollments?: number;
  onHiddenChange: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasSubmittedEither: boolean;
  isLoading?: boolean;
  isEditing: boolean;
};

const titleHidden = "Hidden";
const titleParticipants = "Participants";

export const ListingViewSettings = (props: TListingViewSettingsProps) => {
  const {
    valueHidden,
    valueParticipants,
    valueEnrollments,
    onHiddenChange,
    handleChange,
    hasSubmittedEither,
    isLoading,
    isEditing,
  } = props;

  if (isLoading) {
    return (
      <div className="flex flex-col gap-4 p-3 border border-gray-200 rounded-lg">
        <div className="">
          <label className="form-label">{titleHidden}</label>
          <DefaultSkeleton
            className="px-[0px] py-[0px] max-w-12"
            classNameInner="h-[24px] my-[2px]"
          />
        </div>
        <div className="">
          <label className="form-label">{titleParticipants}</label>
          <DefaultSkeleton
            className="px-[0px] pt-[0px] pb-[16px] max-w-full"
            classNameInner="h-[28px] mb-0 mt-0"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 p-3 border border-gray-200 rounded-lg">
      <div className="">
        <label className="form-label">{titleHidden}</label>
        <CFormSwitch
          size="xl"
          valid={hasSubmittedEither}
          onChange={onHiddenChange}
          value={valueHidden ? 1 : 0}
          checked={valueHidden}
          disabled={isEditing}
        />
      </div>
      <div className="flex flex-col">
        {!isEditing ? (
          <>
            <label className="form-label">{titleParticipants}</label>
            <p className="text-xl font-bold">{valueEnrollments}</p>
          </>
        ) : (
          <CFormRange
            id="participants"
            min={valueEnrollments ? valueEnrollments : MIN_PARTICIPANTS}
            max={MAX_PARTICIPANTS}
            label={
              <div className="flex flex-col mb-[-10px]">
                <span>Participants: {valueParticipants}</span>
                <div className="flex justify-between">
                  <span>
                    {valueEnrollments ? valueEnrollments : MIN_PARTICIPANTS}
                  </span>
                  <span>{MAX_PARTICIPANTS}</span>
                </div>
              </div>
            }
            value={valueParticipants}
            onChange={handleChange}
          />
        )}
      </div>
    </div>
  );
};
