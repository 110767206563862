import { EnrollmentStatusBadge } from "@/components/badges/enrollment-status-badge";
import { CustomPagination } from "@/components/forms/custom/custom-pagination/custom-pagination";
import { TableShadowWrapper } from "@/components/forms/custom/custom-table/table-shadow-wrapper";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import { EEnrollmentStatus } from "@/enums/enrollment-enums";
import { authedFetch } from "@/utils/authed-fetch";
import { dateToYearMonthDay } from "@/utils/date-fns";
import { useAuth } from "@clerk/clerk-react";
import {
  cilCalendar,
  cilEnvelopeLetter,
  cilFlagAlt,
  cilSettings,
  cilSwapHorizontal,
  cilTrash,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
  CTooltip,
} from "@coreui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useOfficeListingEnrollmentChangeStatus } from "../hooks/use-office-listing-enrollment-change-status";
import { useOfficeListingEnrollmentDelete } from "../hooks/use-office-listing-enrollment-delete";
import { useAppDispatch } from "@/store/hooks";
import { setModalConfirmation } from "@/store/modal/slice";

type TColumn = {
  key: string;
  label: React.ReactNode;
};

type TQuery = {
  enrollments: {
    created_at: string;
    user_id: string;
    status: EEnrollmentStatus;
    user_email: string;
  }[];
  count: number;
};

const columns: TColumn[] = [
  {
    key: "contact_email",
    label: (
      <div className="flex items-center gap-1 pl-2 flex-nowrap whitespace-nowrap">
        <CIcon icon={cilEnvelopeLetter} /> Contact Email
      </div>
    ),
  },
  {
    key: "enrolled_at",
    label: (
      <div className="flex items-center gap-1 flex-nowrap whitespace-nowrap">
        <CIcon icon={cilCalendar} /> Enrolled At
      </div>
    ),
  },
  {
    key: "status",
    label: (
      <div className="flex items-center gap-1 flex-nowrap">
        <CIcon icon={cilFlagAlt} /> Status
      </div>
    ),
  },
  {
    key: "actions",
    label: (
      <div className="flex items-center gap-1 pr-2 flex-nowrap">
        <CIcon icon={cilSettings} /> Actions
      </div>
    ),
  },
] as const;

export const ListingViewEnrollmentsTable = () => {
  const { listingId = "0" } = useParams();
  const { getToken } = useAuth();
  const dispatch = useAppDispatch();
  const [mouseOverIndex, setMouseOverIndex] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [pageSize /*, setPageSize */] = useState(10);

  const { data, isLoading, refetch } = useQuery<TQuery>({
    queryKey: ["listing-enrollments", page, pageSize],
    queryFn: async ({ queryKey }) => {
      const [_key, _page, _pageSize] = queryKey;
      const pParam = _page ? `p=${_page}` : "";
      const psParam = _pageSize ? `ps=${_pageSize}` : "";

      const params = [pParam, psParam].filter((param) => param).join("&");

      return authedFetch({
        endpoint: `api/office/enrollment/${listingId}${params}`,
        token: await getToken(),
      }).then((res) => res.json());
    },
  });

  const statusMutation = useOfficeListingEnrollmentChangeStatus({
    onError: () => {},
    onSuccess: () => {
      refetch();
    },
  });

  const deleteMutation = useOfficeListingEnrollmentDelete({
    onSuccess: () => {
      refetch();
    },
    onError: () => {},
  });

  const handleStatusChange = (
    status: EEnrollmentStatus,
    enrollmentUserId: string
  ) => {
    statusMutation.mutate({
      id: Number(listingId),
      enrollmentUserId,
      status,
    });
  };

  const handleDelete = (enrollmentUserId: string, userEmail: string) => {
    dispatch(
      setModalConfirmation({
        message: `Are you sure you want to delete this enrollment?`,
        messageSecondary: userEmail,
        isOpen: true,
        title: "Delete Confirmation",
        buttons: {
          confirm: {
            label: "Yes, delete",
            className: "text-white",
            onClick: () => {
              deleteMutation.mutate({
                id: Number(listingId),
                enrollmentUserId,
              });
            },
          },
          cancel: {
            label: "Cancel",
            onClick: () => {},
          },
        },
      })
    );
  };

  const pageCount = Math.ceil((data?.count || 0) / 10);

  return (
    <div className="flex flex-col items-center w-full">
      <TableShadowWrapper>
        <CTable
          className={`bg-white shadow-lg rounded-lg  transition-all`}
          style={{
            marginBottom: 0,
          }}
        >
          <CTableBody
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto auto auto",
            }}
            onMouseLeave={() => {
              setMouseOverIndex(() => null);
            }}
          >
            <CTableRow
              className="text-base font-extrabold tracking-wider text-gray-900 uppercase bg-gray-200"
              style={{
                display: "contents",
              }}
              onMouseOver={() => {
                setMouseOverIndex(() => null);
              }}
            >
              {columns.map((column) => (
                <CTableHeaderCell
                  className="py-4 border-b-2 border-gray-400"
                  scope="col"
                  key={column.key}
                >
                  {column.label}
                </CTableHeaderCell>
              ))}
            </CTableRow>
            {isLoading ? (
              <CTableRow>
                {columns.map((_, index) => (
                  <CTableDataCell key={index}>
                    <DefaultSkeleton />
                  </CTableDataCell>
                ))}
              </CTableRow>
            ) : (
              data?.enrollments?.map((enrollment, _index) => (
                <CTableRow
                  className="text-gray-700 hover:bg-gray-100"
                  key={enrollment.user_id}
                  style={{
                    display: "contents",
                  }}
                  onMouseOver={() => {
                    setMouseOverIndex(() => _index);
                  }}
                  color={_index === mouseOverIndex ? "info" : ""}
                >
                  <CTableDataCell className="flex items-center ml-2">
                    {enrollment?.user_email}
                  </CTableDataCell>
                  <CTableDataCell className="flex items-center">
                    {dateToYearMonthDay(enrollment?.created_at)}
                  </CTableDataCell>
                  <CTableDataCell className="flex items-center text-xl">
                    <EnrollmentStatusBadge status={enrollment?.status} />
                  </CTableDataCell>
                  <CTableDataCell className="flex items-center justify-center">
                    <div className="flex gap-2">
                      <CTooltip content="Change status">
                        <CDropdown direction="center" portal>
                          <CDropdownToggle color="info" className="text-white">
                            <CIcon icon={cilSwapHorizontal} />
                          </CDropdownToggle>

                          <CDropdownMenu>
                            <CDropdownItem
                              onClick={() => {
                                handleStatusChange(
                                  EEnrollmentStatus.INITIATED,
                                  enrollment?.user_id
                                );
                              }}
                              className={`text-xl ${enrollment?.status === EEnrollmentStatus.INITIATED ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                              disabled={
                                enrollment?.status ===
                                EEnrollmentStatus.INITIATED
                              }
                            >
                              <EnrollmentStatusBadge
                                isGray
                                status={EEnrollmentStatus.INITIATED}
                              />
                            </CDropdownItem>
                            <CDropdownItem
                              onClick={() => {
                                handleStatusChange(
                                  EEnrollmentStatus.OFFER_RECEIVED,
                                  enrollment?.user_id
                                );
                              }}
                              className={`text-xl ${enrollment?.status === EEnrollmentStatus.OFFER_RECEIVED ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                              disabled={
                                enrollment?.status ===
                                EEnrollmentStatus.OFFER_RECEIVED
                              }
                            >
                              <EnrollmentStatusBadge
                                isGray
                                status={EEnrollmentStatus.OFFER_RECEIVED}
                              />
                            </CDropdownItem>
                            <CDropdownItem
                              onClick={() => {
                                handleStatusChange(
                                  EEnrollmentStatus.OFFER_SENT_TO_CLIENT,
                                  enrollment?.user_id
                                );
                              }}
                              className={`text-xl ${enrollment?.status === EEnrollmentStatus.OFFER_SENT_TO_CLIENT ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                              disabled={
                                enrollment?.status ===
                                EEnrollmentStatus.OFFER_SENT_TO_CLIENT
                              }
                            >
                              <EnrollmentStatusBadge
                                isGray
                                status={EEnrollmentStatus.OFFER_SENT_TO_CLIENT}
                              />
                            </CDropdownItem>
                            <CDropdownItem
                              onClick={() => {
                                handleStatusChange(
                                  EEnrollmentStatus.OFFER_ACCEPTED,
                                  enrollment?.user_id
                                );
                              }}
                              className={`text-xl ${enrollment?.status === EEnrollmentStatus.OFFER_ACCEPTED ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                              disabled={
                                enrollment?.status ===
                                EEnrollmentStatus.OFFER_ACCEPTED
                              }
                            >
                              <EnrollmentStatusBadge
                                isGray
                                status={EEnrollmentStatus.OFFER_ACCEPTED}
                              />
                            </CDropdownItem>
                            <CDropdownItem
                              onClick={() => {
                                handleStatusChange(
                                  EEnrollmentStatus.AGREEMENT_SIGNED,
                                  enrollment?.user_id
                                );
                              }}
                              className={`text-xl ${enrollment?.status === EEnrollmentStatus.AGREEMENT_SIGNED ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                              disabled={
                                enrollment?.status ===
                                EEnrollmentStatus.AGREEMENT_SIGNED
                              }
                            >
                              <EnrollmentStatusBadge
                                isGray
                                status={EEnrollmentStatus.AGREEMENT_SIGNED}
                              />
                            </CDropdownItem>
                            <CDropdownItem
                              onClick={() => {
                                handleStatusChange(
                                  EEnrollmentStatus.CLOSED,
                                  enrollment?.user_id
                                );
                              }}
                              className={`text-xl ${enrollment?.status === EEnrollmentStatus.CLOSED ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                              disabled={
                                enrollment?.status === EEnrollmentStatus.CLOSED
                              }
                            >
                              <EnrollmentStatusBadge
                                isGray
                                status={EEnrollmentStatus.CLOSED}
                              />
                            </CDropdownItem>
                          </CDropdownMenu>
                        </CDropdown>
                      </CTooltip>

                      <CButton
                        type="button"
                        color="danger"
                        size="sm"
                        className="text-white"
                        onClick={() => {
                          handleDelete(
                            enrollment?.user_id,
                            enrollment?.user_email
                          );
                        }}
                      >
                        <CIcon icon={cilTrash} />
                      </CButton>
                    </div>
                  </CTableDataCell>
                </CTableRow>
              ))
            )}
          </CTableBody>
        </CTable>
        {!isLoading && data?.count === 0 && (
          <div className="flex flex-col items-center justify-center h-64">
            <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg shadow-lg">
              <p className="text-xl font-bold text-gray-700">
                No Data Available
              </p>
              <p className="mt-2 text-center text-gray-500">
                There is currently no data to display. Create your first item!
              </p>
              <p className="mt-2 text-center text-gray-500">
                In case of issues, reach out to{" "}
                <a
                  href="mailto:support@machinevertical.com"
                  className="text-blue-500 underline"
                >
                  support@machinevertical.com
                </a>
              </p>
            </div>
          </div>
        )}
      </TableShadowWrapper>

      <CustomPagination
        pageCount={pageCount}
        currentPage={page}
        onPageChange={setPage}
      />
    </div>
  );
};
