import { CFormSwitch, CInputGroup, CInputGroupText } from "@coreui/react";
import { TListingEditForm } from "../listing-view-edit";
import CustomInput from "@/components/forms/custom/custom-input/custom-input";
import { DefaultSkeleton } from "@/components/loaders/default-skeleton";
import CIcon from "@coreui/icons-react";
import { cilEuro } from "@coreui/icons";

type TListingViewRestrictedBudgetProps = {
  value: TListingEditForm["budget"];
  error?: string;
  hasSubmittedEither: boolean;
  switchOnChange: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isEditing: boolean;
  isLoading?: boolean;
  isRestrictedBudget: boolean;
};

const title = "Restricted Budget";

export const ListingViewRestrictedBudget = (
  props: TListingViewRestrictedBudgetProps
) => {
  const {
    value,
    error,
    switchOnChange,
    handleChange,
    isEditing,
    isLoading,
    isRestrictedBudget,
    hasSubmittedEither,
  } = props;

  if (isLoading) {
    return (
      <div className="">
        <label className="form-label">{title}</label>
        <DefaultSkeleton
          className="px-[0px] py-[0px] max-w-full"
          classNameInner="h-[30px]"
        />
      </div>
    );
  }

  if (!isEditing) {
    return (
      <div className="">
        <label className="form-label">{title}</label>

        <p className="px-[12px] py-[6px] h-[44px] mb-0 rounded flex items-center font-bold text-xl">
          {isRestrictedBudget ? (
            <>
              {value}
              <CIcon icon={cilEuro} size="xl" />
            </>
          ) : (
            <span className="text-lg font-normal">not assigned</span>
          )}
        </p>
      </div>
    );
  }

  return (
    <div className="">
      <label className="form-label">{title}</label>
      <div className="flex">
        <CInputGroup className="sm:flex-1">
          <CInputGroupText
            style={
              isRestrictedBudget
                ? {}
                : {
                    border: "transparent",
                  }
            }
          >
            <CFormSwitch
              size="xl"
              onChange={switchOnChange}
              checked={isRestrictedBudget}
            />
          </CInputGroupText>
          {isRestrictedBudget && (
            <>
              <CustomInput
                id="budget"
                type="number"
                disabled={!isRestrictedBudget}
                value={value}
                onChange={handleChange}
                valid={!!error && hasSubmittedEither}
              />
              <CInputGroupText>€</CInputGroupText>
            </>
          )}
        </CInputGroup>
        <div className="sm:flex-1 sm:h-[44px]" />
      </div>
    </div>
  );
};
