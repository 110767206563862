import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { MyEnrollmentsTable } from "./components/my-enrollments-table";
import { useEffect } from "react";

export const PageEnrollments = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        link: "/",
        label: "Home",
      },
      {
        label: "My Enrollments",
        link: "/enrollments",
      },
    ]);
  }, []);
  return (
    <div>
      <MyEnrollmentsTable />
    </div>
  );
};
