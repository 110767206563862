import { EListingStatus } from "@/enums/listing-enums";
import { Fragment, PropsWithChildren } from "react";

type TStepInProcessProps = {
  status?: EListingStatus;
};

type TBallProps = {
  numberOfStep: number;
  isActive: boolean;
  isCurrent: boolean;
} & PropsWithChildren;

type TLinePrpps = {
  isActive: boolean;
};

const Circle = (props: TBallProps) => {
  const { isActive, children, numberOfStep, isCurrent } = props;
  return (
    <div className="relative flex flex-col items-center">
      <div
        className={`rounded-full font-bold text-white w-10 h-10 ${isActive ? "bg-green-500" : "bg-slate-400"} ${isCurrent ? "scale-110" : ""} flex items-center justify-center`}
      >
        {numberOfStep}
      </div>
      <div
        className={`absolute bottom-[-30px] ${isActive ? "text-green-500" : "text-slate-400"} ${isCurrent ? "border-b-2 font-bold border-green-500" : ""}`}
      >
        {children}
      </div>
    </div>
  );
};

const Line = (props: TLinePrpps) => {
  const { isActive } = props;
  return (
    <div
      className={`border-b flex-1 h-1 ${isActive ? "border-green-500" : ""}`}
    >
      {" "}
    </div>
  );
};

export const StepInProcess = (props: TStepInProcessProps) => {
  const processes = [
    {
      status: EListingStatus.DRAFT,
      title: "Draft",
    },
    {
      status: EListingStatus.OPEN,
      title: "Open",
    },
    {
      status: EListingStatus.STARTED,
      title: "Started",
    },
    {
      status: EListingStatus.CLOSED,
      title: "Closed",
    },
  ];

  return (
    <div className="flex items-center justify-between w-full gap-4 px-6 pb-10">
      {processes.map((process, index) => {
        const isActive =
          index <= processes.findIndex((p) => p.status === props.status);
        const isCurrent =
          index === processes.findIndex((p) => p.status === props.status);
        if (index === 0) {
          return (
            <Circle
              key={index}
              numberOfStep={index + 1}
              isActive={isActive}
              isCurrent={isCurrent}
            >
              {process.title}
            </Circle>
          );
        } else {
          return (
            <Fragment key={index}>
              <Line isActive={isActive} />
              <Circle
                numberOfStep={index + 1}
                isActive={isActive}
                isCurrent={isCurrent}
              >
                {process.title}
              </Circle>
            </Fragment>
          );
        }
      })}
    </div>
  );
};
