import { AttachmentTable } from "@/components/attachments/attachment-table/attachment-table";
import { CustomFileInput } from "@/components/attachments/custom-file-input/custom-file-input";
import {
  MAX_ATTACHMENT_SIZE,
  MAX_ATTACHMENTS,
} from "@/constants/form-constatns";
import { dateToYearMonthDay } from "@/utils/date-fns";
import { integerToFilesize } from "@/utils/integer-to-filesize";
import { cilActionUndo, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CTooltip } from "@coreui/react";
import { useEffect, useRef } from "react";

type TListingViewAttachmentsProps = {
  files: File[];
  isEditingMode: boolean;
  handlerFilesOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  attachmentUrlsToDelete: string[];
  handleAttachmentToDelete: ({
    url,
    indexOfNew,
  }: {
    url: string;
    indexOfNew?: number;
  }) => void;
  pageRef: React.RefObject<HTMLDivElement>;
  attachmentsFromServer?: {
    attachment_url: string;
    display_name: string;
    size: number;
    created_at: string;
    created_by: string;
    indexOfNew?: number;
  }[];
};

// @ts-ignore
const endpoint = import.meta.env.VITE_API_ENDPOINT;

export const ListingViewAttachments = ({
  files,
  isEditingMode,
  handlerFilesOnChange,
  attachmentUrlsToDelete,
  handleAttachmentToDelete,
  pageRef,
  attachmentsFromServer,
}: TListingViewAttachmentsProps) => {
  const fileInputFormRef = useRef<HTMLFormElement>(null);

  const attachmentRowsCount =
    (attachmentsFromServer || []).length + files.length;
  const totalEstimatedCount =
    attachmentRowsCount - attachmentUrlsToDelete.length;

  const isTooManyEstimatedAttachments = totalEstimatedCount > MAX_ATTACHMENTS;

  useEffect(() => {
    if (files.length === 0) {
      fileInputFormRef.current?.reset();
    }
  }, [files]);

  return (
    <>
      <div className="flex flex-col items-center w-full">
        <span
          className={`${isTooManyEstimatedAttachments ? "text-red-500" : ""}`}
        >
          Listings can have no more than{" "}
          <span className="font-bold">{MAX_ATTACHMENTS}</span> attachments.
        </span>
        {isTooManyEstimatedAttachments ? (
          <span className="text-red-500">
            Please remove to make it 5 or less.
          </span>
        ) : null}
        <span>
          Each attachment must be of size less than{" "}
          <span className="font-bold">
            {integerToFilesize(MAX_ATTACHMENT_SIZE)}
          </span>
          .
        </span>
      </div>

      <AttachmentTable
        isRed={totalEstimatedCount > MAX_ATTACHMENTS}
        isEditing={isEditingMode}
        attachments={
          [
            ...(attachmentsFromServer || []),
            ...files.map((file, index) => {
              return {
                indexOfNew: index,
                display_name: file.name,
                attachment_url: URL.createObjectURL(file),
                size: file.size,
                created_at: new Date().toISOString(),
                created_by: "You",
              };
            }),
          ].map((file) => {
            const splitName = file?.display_name.split(".");
            const type = splitName[splitName.length - 1] || "";
            const fileUrl =
              file?.indexOfNew !== null && file?.indexOfNew !== undefined
                ? file?.attachment_url
                : `${file?.attachment_url}`; // ternary remains for future adjustments

            return {
              fileUrl,
              name: file?.display_name,
              size: integerToFilesize(file?.size),
              type,
              uploadedAt: dateToYearMonthDay(file?.created_at),
              uploadedBy: file?.created_by,
              markedToDelete: attachmentUrlsToDelete.includes(
                file?.attachment_url
              ),
              indexOfNew: file?.indexOfNew ?? null,
              actions: (
                <div className="flex justify-center w-auto gap-1">
                  <CTooltip
                    content={
                      attachmentUrlsToDelete.includes(file?.attachment_url)
                        ? "Cancel"
                        : "Remove"
                    }
                    container={pageRef.current}
                  >
                    <CButton
                      color={
                        attachmentUrlsToDelete.includes(file?.attachment_url)
                          ? "info"
                          : "danger"
                      }
                      size="sm"
                      className="rounded-full"
                      onClick={() => {
                        handleAttachmentToDelete({
                          url: file?.attachment_url,
                          indexOfNew:
                            file?.indexOfNew !== null &&
                            file?.indexOfNew !== undefined
                              ? file.indexOfNew
                              : undefined,
                        });
                      }}
                    >
                      <span className="text-white">
                        <CIcon
                          icon={
                            attachmentUrlsToDelete.includes(
                              file?.attachment_url
                            )
                              ? cilActionUndo
                              : cilTrash
                          }
                        />
                      </span>
                    </CButton>
                  </CTooltip>
                </div>
              ),
            };
          }) || []
        }
        isWithActions={isEditingMode}
      />
      <form ref={fileInputFormRef}>
        <CustomFileInput
          id="file"
          isEditing={isEditingMode}
          multiple
          files={files}
          onChange={handlerFilesOnChange}
          rightSide={
            <span>
              Listing will have{" "}
              <span className="font-bold">{totalEstimatedCount}</span>{" "}
              attachments
            </span>
          }
          rightSideIsError={totalEstimatedCount > MAX_ATTACHMENTS}
        />
      </form>
    </>
  );
};
