import { PageAuthSwitch } from "@/auth/page-auth-switch";
import OfficeUserListings from "@/views/pages/office-user/listings/listings";
import ListingsCreate from "@/views/pages/office-user/listings/create/listing-create";
import ListingsViewEdit from "@/views/pages/office-user/listings/view/listing-view-edit";
import React from "react";
import { PageDiscover } from "@/views/pages/equipment-provider/discover/page-discover";
import { PageEnrollments } from "@/views/pages/equipment-provider/enrollments/page-enrollments";
import { PageDiscoverShow } from "@/views/pages/equipment-provider/discover/show/page-discover-show";
import PageMyDrafts from "@/views/pages/office-user/my-drafts/page-my-drafts";
import { ListingGroup } from "@/views/pages/office-user/listing-group/listing-group";
import { HomeOfficeUser } from "@/views/pages/office-user/home/home-office-user";
import { HomeEquipmentProvider } from "@/views/pages/equipment-provider/home/home-equipment-provider";
import { Dashboard } from "@/views/pages/office-user/dashboard/dashboard";
import { GroupView } from "@/views/pages/office-user/listing-group/group-view/group-view";
import { CreateGroup } from "@/views/pages/office-user/listing-group/create/create-group";
import { TermsAndConditions } from "@/views/pages/common/terms-and-conditions";
import { ProcessOverview } from "@/views/pages/common/process-overview";

const LoginRedirect = React.lazy(
  () => import("../views/pages/auth-redirects/login-redirect")
);
const RootRedirect = React.lazy(
  () => import("../views/pages/auth-redirects/root-redirect")
);
const EquipmentProviderLayout = React.lazy(
  () => import("../layout/equipment-provider/equipment-provider-layout")
);
const OfficeUserLayout = React.lazy(
  () => import("../layout/office-user/office-user-layout")
);

const Login = React.lazy(() => import("../views/pages/login/login"));
const Page404 = React.lazy(() => import("../views/pages/page404/page404"));

type TDefaultRoutes = {
  path: string;
  element: React.ReactNode;
  children?: TDefaultRoutes;
}[];

export const defaultRoutes: TDefaultRoutes = [
  {
    path: "/",
    element: (
      <PageAuthSwitch
        defaultElement={<LoginRedirect />}
        equipmentProviderElement={<EquipmentProviderLayout />}
        officeUserElement={<OfficeUserLayout />}
      />
    ),
    children: [
      {
        path: "/",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            equipmentProviderElement={<HomeEquipmentProvider />}
            officeUserElement={<HomeOfficeUser />}
          />
        ),
      },
      {
        path: "/dashboard",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            equipmentProviderElement={<RootRedirect />}
            officeUserElement={<Dashboard />}
          />
        ),
      },
      {
        path: "/listing-group",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            equipmentProviderElement={<RootRedirect />}
            officeUserElement={<ListingGroup />}
          />
        ),
      },
      {
        path: "/listing-group/create",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            equipmentProviderElement={<RootRedirect />}
            officeUserElement={<CreateGroup />}
          />
        ),
      },
      {
        path: "/listing-group/:groupId",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            equipmentProviderElement={<RootRedirect />}
            officeUserElement={<GroupView />}
          />
        ),
      },
      {
        path: "/listing-group/:groupId/listing/create",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            equipmentProviderElement={<RootRedirect />}
            officeUserElement={<ListingsCreate />}
          />
        ),
      },
      {
        path: "/listing-group/:groupId/listing/:listingId",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            equipmentProviderElement={<RootRedirect />}
            officeUserElement={<ListingsViewEdit />}
          />
        ),
      },
      {
        path: "/listing",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            equipmentProviderElement={<RootRedirect />}
            officeUserElement={<OfficeUserListings />}
          />
        ),
      },
      {
        path: "/my-drafts",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            equipmentProviderElement={<RootRedirect />}
            officeUserElement={<PageMyDrafts />}
          />
        ),
      },
      {
        path: "/discover",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            equipmentProviderElement={<PageDiscover />}
            officeUserElement={<RootRedirect />}
          />
        ),
      },
      {
        path: "/discover/:listingId",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            equipmentProviderElement={<PageDiscoverShow />}
            officeUserElement={<OfficeUserListings />}
          />
        ),
      },
      {
        path: "/enrollments",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            equipmentProviderElement={<PageEnrollments />}
            officeUserElement={<RootRedirect />}
          />
        ),
      },
      {
        path: "/terms-and-conditions",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            equipmentProviderElement={<TermsAndConditions />}
            officeUserElement={<TermsAndConditions />}
          />
        ),
      },
      {
        path: "/process-overview",
        element: (
          <PageAuthSwitch
            defaultElement={<LoginRedirect />}
            equipmentProviderElement={<ProcessOverview />}
            officeUserElement={<ProcessOverview />}
          />
        ),
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "/login",
    element: (
      <PageAuthSwitch
        defaultElement={<Login />}
        equipmentProviderElement={<RootRedirect />}
        officeUserElement={<RootRedirect />}
      />
    ),
  },
  {
    path: "*",
    element: <Page404 />,
  },
];
