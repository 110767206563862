type TLoadingOrDataProps = {
  data: string | number;
  isLoading: boolean;
  before?: string;
  after?: string;
};

export const loadingOrData = (props: TLoadingOrDataProps) => {
  const { data, isLoading, before = "", after = "" } = props;

  if (isLoading) {
    return "loading...";
  }
  if (!data) {
    return "-";
  }
  return `${before}${data}${after}`;
};
